import React, { useContext, useMemo, useState } from 'react';

import * as S from './styles';
import { Formik } from 'formik';

import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import FieldFormik, {
  IFormOptions,
} from '../../../../../components/newStudent/FieldFormik';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import { formatDate } from '../../../../../util/common/appUtil';
import { myDataServiceNewStudent } from '../../../../../services/newStudent';
import { sieadImagesFolderUrl } from '../../../../../variables';
import PasswordChange from '../../../../../components/newStudent/Modal/PasswordChange';

const avatarCircle = '/assets/img/circle_blue.png';
const avatar = '/img/newStudent/avatar.png';
const plus = `${sieadImagesFolderUrl}plus.svg`;

interface Props {
  poleId?: string | number;
  poleOptions: IFormOptions[];
}
export default function InfoContainer({ poleOptions }: Props) {
  const { student, setStudent, setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;
  const [isOpenPasswordChanged, setIsOpenPasswordChanged] = useState(false);
  const options = [{ value: 0, title: '[ Selecione ]' }, ...poleOptions];
  const initialValues = {
    poleId: JSON.stringify(student?.dataStudent?.poleId),
  };

  const [reloadedImage, setReloadedImage] = useState(0);

  const handleSave = async (id: number) => {
    const { result } = await myDataServiceNewStudent.changePoleOrRace({
      poleId: id <= 0 ? null : id,
    });
    if (result) {
      const tmpStudent = {
        ...student,
        dataStudent: { ...student?.dataStudent, poleId: id },
      };

      setReloadedImage(reloadedImage + 1);
      localStorage.setItem('dataStudent', JSON.stringify(tmpStudent));
      setStudent(tmpStudent);
    }
  };

  const handleFileChange = async (file: File) => {
    if (!file) return;

    try {
      setIsLoading(true);
      const { data, result } = await myDataServiceNewStudent.changePhoto(file);
      if (result) {
        const tmpStudent = {
          ...student,
          dataStudent: { ...student?.dataStudent, photo: data },
        };

        localStorage.setItem('dataStudent', JSON.stringify(tmpStudent));
        setStudent(tmpStudent);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error.message);
    }
  };

  const renderImage = useMemo(() => {
    const image = student?.dataStudent.photo
      ? student?.dataStudent.photo
      : avatar;

    return reloadedImage % 2 === 1 ? (
      <S.ImgAvatar src={image} />
    ) : (
      <>
        <S.ImgAvatar src={image} />
      </>
    );
  }, [reloadedImage, student?.dataStudent.photo]);

  return (
    <S.Container>
      <S.ImageContainer>
        <S.PrefilContent>
          {renderImage}
          <S.ImgCircle src={avatarCircle} />
          <S.InputFilePlus>
            <span>
              <S.ImgPlus src={plus} />
              <input
                type="file"
                className="input-file"
                onChange={({ target }) => {
                  target.files && handleFileChange(target.files[0]);
                }}
              />
            </span>
          </S.InputFilePlus>
        </S.PrefilContent>
        <S.InputFile className="button_change_perfil">
          <span>Alterar foto perfil</span>
          <input
            type="file"
            className="input-file"
            onChange={({ target }) => {
              target.files && handleFileChange(target.files[0]);
            }}
          />
        </S.InputFile>
      </S.ImageContainer>
      <S.Content className="content">
        <S.H3 className="content_name">{student?.dataStudent.name}</S.H3>
        <S.Password>
          <div>
            <S.P>
              Matriculado em {formatDate(student?.dataStudent.registerDate)}
            </S.P>
          </div>
          <ButtonSimple
            color="success"
            widthSize="xmedium"
            heightSize="small"
            onClick={() => setIsOpenPasswordChanged(true)}
          >
            Alterar senha
          </ButtonSimple>
        </S.Password>
        <S.Polo>
          <Formik
            initialValues={initialValues}
            onSubmit={() => {}}
            enableReinitialize
          >
            {({ handleChange, values }) => (
              <S.StyledForm>
                <FieldFormik
                  heightSize="medium"
                  name="poleId"
                  label="Polo"
                  type="select"
                  value={values.poleId}
                  onChange={handleChange}
                  options={options}
                  widthSize="fullWidth"
                />
                {student &&
                  student.dataStudent?.poleId !== +values.poleId &&
                  +values.poleId !== 0 && (
                    <ButtonSimple
                      color="primary"
                      heightSize="medium"
                      widthSize="xmedium"
                      onClick={() => handleSave(+values.poleId)}
                    >
                      Salvar
                    </ButtonSimple>
                  )}
              </S.StyledForm>
            )}
          </Formik>
        </S.Polo>
      </S.Content>
      <PasswordChange
        isOpen={isOpenPasswordChanged}
        setIsOpen={setIsOpenPasswordChanged}
      />
    </S.Container>
  );
}
