import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';
import CoursesCommon from '../CoursesCommon';
import { IFormOptions } from '../../../../../components/newStudent/FieldFormik';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import DownloadIcon from '@mui/icons-material/Download';
import {
  AlertBar,
  AletNotfoundLoading,
} from '../../../../../components/newStudent/Alert';
import { certificateServiceNewStudent } from '../../../../../services/newStudent';
import { courseTypeGraduation } from '../../../../../variables';
import { ICertificate } from '../../../../../services/newStudent/certificate/certificate.service';
import TableBasic, {
  ITableData,
} from '../../../../../components/newStudent/Table';
import ButtonIcon from '../../../../../components/newStudent/Buttons/ButtonIcon';
import { formatDate } from '../../../../../util/common/appUtil';

export default function Graduation() {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [courses, setCourses] = useState<IFormOptions[]>([]);
  const [tableData, setTableData] = useState<ITableData>({
    title: [],
    content: [],
  });
  const [reload, setReload] = useState(1);

  useEffect(() => {
    setIsLoading(true);
    const load = async () => {
      try {
        const { data, result } = await certificateServiceNewStudent.show({
          type: +courseTypeGraduation!,
        });
        if (result) {
          const tmpCourses = data.certificates.reduce<ICertificate[]>(
            (acc, current) => {
              const arr = acc.find(
                (item) => item.registrationId === current.registrationId,
              );
              if (!arr) {
                return acc.concat(current);
              } else return acc;
            },
            [],
          );

          setCourses([
            { value: 0, title: '[ Selecione o curso ]' },
            ...tmpCourses.map((item) => ({
              title: item.courseName,
              value: item.registrationId,
            })),
          ]);
        }
        const dataFiltered = data.certificates
          ? data.certificates.filter((i) => i.date)
          : [];
        setTableData({
          title: [
            { item: <S.TitleH6>Curso</S.TitleH6>, noPadding: true },
            { item: <S.TitleH6>Status</S.TitleH6>, noPadding: true },
            {
              item: <S.TitleH6>Tipo de Certificado</S.TitleH6>,
              noPadding: true,
            },
            {
              item: <S.TitleH6>Data da Solicitação</S.TitleH6>,
              noPadding: true,
            },
            { item: <S.TitleH6>Download</S.TitleH6>, noPadding: true },
            { item: <S.TitleH6>Observação</S.TitleH6>, noPadding: true },
          ],
          content:
            dataFiltered.length > 0
              ? dataFiltered.map((item) => ({
                  course: {
                    item: (
                      <S.TableDiv>
                        <S.TableH6>{item.courseName}</S.TableH6>
                      </S.TableDiv>
                    ),
                    noPadding: true,
                  },
                  status: {
                    item: <S.TableH6>{item.status}</S.TableH6>,
                    noPadding: true,
                  },
                  type: {
                    item: <S.TableH6>{item.type}</S.TableH6>,
                    noPadding: true,
                  },
                  date: {
                    item: <S.TableH6>{formatDate(item.date)}</S.TableH6>,
                    noPadding: true,
                  },
                  download: {
                    item: item.url &&
                      item.type.toLowerCase().includes('digital') && (
                        <S.TableDivButton>
                          <ButtonIcon
                            onClick={() => window.open(item.url)}
                            color="success"
                            icon={<DownloadIcon fontSize="large" />}
                          />
                        </S.TableDivButton>
                      ),
                    noPadding: true,
                    align: 'center',
                  },

                  observation: {
                    item: (
                      <S.TableDiv>
                        <S.TableH6>{item.observation}</S.TableH6>
                      </S.TableDiv>
                    ),
                    noPadding: true,
                  },
                }))
              : [],
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw new Error(error);
      }
    };
    load();
  }, [reload]);
  return (
    <S.Container>
      {courses.length <= 1 ? (
        <AletNotfoundLoading toRender />
      ) : (
        <>
          <AlertBar
            color="tertiary"
            text="Após solicitação, a emissão do seu certificado ficará disponível para download em até 7 dias úteis"
          />
          <TableBasic data={tableData} unableNotFound />
          <CoursesCommon
            courses={courses}
            reload={reload}
            setReload={setReload}
          />
        </>
      )}
    </S.Container>
  );
}
