import React, { useContext, useState } from 'react';

import * as S from './styles';
import PrintIcon from '@mui/icons-material/Print';

import TableBasic, {
  ITableData,
} from '../../../../../components/newStudent/Table';
import ButtonIcon from '../../../../../components/newStudent/Buttons/ButtonIcon';
import { CardLeftColor } from '../../../../../components/newStudent/Card';
import { AlertWarning } from '../../../../../components/newStudent/Alert';
import { handleMaxWidth } from '../../../../../util/newStudent';
import { useTheme } from 'styled-components';
import Download from '@mui/icons-material/Download';
import Divider from '../../../../../components/common/Divider';
import {
  ICourseCategory,
  IStatementsRequirements,
  statementsServiceNewStudent,
} from '../../../../../services/newStudent/statements/statements.service';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import { BasicDialog } from '../../../../../components/newStudent/Dialog';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IRulesToSend } from '../../../../../services/newStudent/myCourses/myCourses.service';
interface Props {
  partial?: ICourseCategory[];
  requirements?: IStatementsRequirements[];
  isOpen: boolean;
}
export default function PartialStatement({
  partial,
  isOpen,
  requirements,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const theme = useTheme();
  const [reeded, setReaded] = useState(false);
  const [rules, setRules] = useState<IRulesToSend>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderRules = async () => {
    try {
      setIsLoading(true);
      const { result, data } =
        await statementsServiceNewStudent.getStatementsRules(3);
      if (result) {
        setIsModalOpen(true);
        setRules(data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDocument = async (id: number) => {
    try {
      setIsLoading(true);
      const { result, data } = await statementsServiceNewStudent.getDocument(
        id,
        3,
      );
      if (result) {
        window.open(data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const data: ITableData = {
    title: [
      {
        item: <h5 style={{ fontWeight: 'bold' }}>Curso</h5>,
      },

      {
        item: <h5 style={{ fontWeight: 'bold' }}>Declaração</h5>,
        align: 'center',
      },
    ],
    content:
      partial?.map((item) => ({
        Curso: {
          item: (
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              <h6 style={{ fontWeight: 'bold' }}>{item.courseName}</h6>|
              <h6>{item.type}</h6>
            </div>
          ),
        },
        Declaração: {
          item: (
            <ButtonIcon
              color="success"
              icon={<PrintIcon fontSize="large" />}
              heightSize="small"
              onClick={() => getDocument(item.id)}
              disabled={!reeded}
            />
          ),
          align: 'center',
        },
      })) ?? [],
  };

  return (
    <S.Container>
      {isOpen && (
        <S.Focus>
          {isOpen &&
            requirements?.map((item, index) => (
              <S.FocusCard key={index}>
                <CardLeftColor color="primary" heightSize={'fullHeight'}>
                  <S.ContentRequest>
                    <S.TitleCard>{item.title}</S.TitleCard>
                    {item.structure.map((structured, i) => (
                      <div key={index + '_' + i}>
                        <S.KeyValue>
                          <S.Key>{structured.title}:</S.Key>
                          <S.Value>{structured.status}</S.Value>
                        </S.KeyValue>
                      </div>
                    ))}
                  </S.ContentRequest>
                </CardLeftColor>
              </S.FocusCard>
            ))}
          {handleMaxWidth(theme.size?.medium) ? (
            partial?.map((row, index) => (
              <S.RegistrationRow key={index}>
                <S.Row>
                  <S.RowKey>
                    <S.Key>Curso</S.Key>
                  </S.RowKey>
                  <S.RowValue>
                    <S.Value>{row.courseName}</S.Value>
                  </S.RowValue>
                </S.Row>
                <S.Row>
                  <S.RowKey>
                    <S.Key>Declaração</S.Key>
                  </S.RowKey>
                  <S.RowValue>
                    <S.Value>
                      <ButtonIcon
                        color="success"
                        icon={<Download fontSize="large" />}
                        onClick={() => getDocument(row.id)}
                      />
                    </S.Value>
                  </S.RowValue>
                </S.Row>
                <Divider color="gray02" />
              </S.RegistrationRow>
            ))
          ) : (
            <TableBasic data={data} />
          )}
          <S.DivReaded>
            <input
              name="reeded"
              onChange={({ target }) => setReaded(target.checked)}
              type="checkbox"
            />
            <h6>
              Li e concordo com as{' '}
              <S.A onClick={renderRules}>Regras para emissão da declaração</S.A>
            </h6>
          </S.DivReaded>
          <AlertWarning title="Caso tenha alguma dúvida para emitir a declaração, nos chame pelo chat online." />
        </S.Focus>
      )}
      {rules && (
        <BasicDialog
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          scroll={'body'}
        >
          <S.DialogComponent>
            <S.DialogTitleDiv>
              <div>
                <S.DialogTitle>{rules.title}</S.DialogTitle>
                {rules.information && <S.Value>{rules.information}</S.Value>}
              </div>
              <IconButton onClick={() => setIsModalOpen(false)}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </S.DialogTitleDiv>
            {rules.structure.map((item, index) => (
              <S.KeyValueDialog key={index}>
                <S.Key>{item.title}</S.Key>
                {item.text.map((text, i) => (
                  <S.Value key={index + '_' + i}>{text}</S.Value>
                ))}
              </S.KeyValueDialog>
            ))}
          </S.DialogComponent>
        </BasicDialog>
      )}
    </S.Container>
  );
}
