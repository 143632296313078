import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import LockIcon from '@mui/icons-material/Lock';

import { Box, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';

import * as S from './styles';
import { useTheme } from 'styled-components';

export interface IDataBasicTab {
  title: string;
  children: ReactNode;
  disabled?: boolean;
  id: number;
}
interface Props {
  data: IDataBasicTab[] | [];
  defaultValue?: string;
  setValueSelected?: Dispatch<SetStateAction<string>>;
}

export default function BasicTab({
  data,
  defaultValue,
  setValueSelected,
}: Props) {
  const [value, setValue] = useState('1');
  const theme = useTheme();

  useEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (_, newValue: string) => {
    setValue(newValue);
    setValueSelected && setValueSelected(newValue);
  };

  useEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  const styleTitle = {
    '&.Mui-selected': {
      color: theme.colors?.text.primary,
      backgroundColor: theme.colors?.gray01,
      fontWeight: 'bold',
      borderRadius: '12px 12px 0 0',
    },
    marginRight: '1rem',
    fontSize: '13px',
    color: theme.colors?.text.secondary ? theme.colors?.text.secondary : 'gray',
    backgroundColor: theme.colors?.gray02 ? theme.colors?.gray02 : 'gray',
    borderRadius: '12px 12px 0 0',
    textTransform: 'capitalize',
    textDecoration: 'none',
    '&:last-child': {
      marginRight: '0',
    },
  };

  return (
    <S.Container>
      <S.Content>
        <TabContext value={value}>
          <Box sx={{ borderBottom: '1px solid #e0e0e0' }}>
            <TabList
              variant="fullWidth"
              onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {data.map((item, index) => {
                return (
                  <Tab
                    icon={
                      item?.disabled ? <LockIcon fontSize="large" /> : <></>
                    }
                    disabled={item?.disabled ?? false}
                    iconPosition="start"
                    sx={styleTitle}
                    label={item?.title}
                    value={data[index].id.toString()}
                    key={index + 1}
                  />
                );
              })}
            </TabList>
          </Box>
          {data.map((item, index) => {
            return (
              <S.StyledTabPanel key={index} value={data[index].id.toString()}>
                {item?.children}
              </S.StyledTabPanel>
            );
          })}
        </TabContext>
      </S.Content>
    </S.Container>
  );
}
