import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #ededed;
  min-height: 100vh;
  height: 100%;
`;

export const Content = styled.div`
  max-width: 425px;
  width: 90vw;
  min-height: 250px;
  background-color: white;
  padding: 10px 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div``;
export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleDiv = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Dot = styled.div`
  width: 20px;
  height: 20px;
  background-color: gray;
  border-radius: 50%;
`;

export const Title = styled.h6`
  color: gray;
  font-weight: bold;
`;

export const ImageLogo = styled.img`
  width: 100px;
  padding-bottom: 0.5rem;
`;

export const Subtitle = styled.p`
  color: gray;
  font-size: 12px;
`;

export const KeyValue = styled.div`
  display: flex;
  max-width: 100%;
  word-break: break-all;
  align-items: baseline;
  gap: 0.5rem;
  color: gray;
`;

export const Key = styled.p`
  color: gray;
  font-size: 12px;
  line-height: 14px;
`;

export const Value = styled.p`
  color: gray;
  font-size: 12px;
  line-height: 14px;
`;

export const CardForm = styled.div`
  display: flex;
  padding: 20px;
  gap: 1rem;
  flex-direction: column;

  @media screen and (max-width: 400px) {
    padding: 0;
  }
`;

export const PaymentTypeDiv = styled.p`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Chip = styled.img`
  width: 40px;
  z-index: 100;
  position: relative;
  right: 115px;
  bottom: 40px;

  @media screen and (max-width: 400px) {
  }
`;

export const TypeCartImg = styled.img`
  width: 40px;
  z-index: 100;
  position: relative;
  right: 115px;
  position: absolute;
  bottom: 30px;
  left: 265px;
`;

export const CardNumber = styled.p`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-left: 10px;
  color: white;
  position: absolute;
  bottom: 60px;
  left: 30px;
`;

export const CardName = styled.p`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  margin-left: 10px;
  color: white;
  position: absolute;
  bottom: 30px;
  left: 30px;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;

  @media screen and (max-width: 425px) {
    max-width: 200px;
  }
`;

export const CardTitleDue = styled.p`
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-left: 10px;
  color: white;
  position: absolute;
  bottom: 40px;
  left: 30px;
`;

export const Line = styled.p`
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 100%;
  height: 30px;
  margin-left: 10px;
  background-color: gray;
  color: gray;
  position: absolute;
  bottom: 120px;
  left: -10px;
`;

export const CardTitleCvv = styled.p`
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-left: 10px;
  color: white;
  position: absolute;
  bottom: 40px;
  left: 100px;
`;

export const CardDue = styled.p`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  margin-left: 10px;
  color: white;
  position: absolute;
  bottom: 30px;
  left: 30px;
`;

export const CardCVV = styled.p`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  margin-left: 10px;
  color: white;
  position: absolute;
  bottom: 30px;
  left: 100px;
`;

export const PaymentType = styled.p`
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  color: gray;
`;

export const Checkbox = styled.div`
  width: 12px;
  height: 12px;
  background-color: transparent;
  border-radius: 100%;
  border: 4px solid gray;
`;

export const Form = styled.form`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const CardContainer = styled.div`
  perspective: 1000px;
  width: 100%;
`;

export const Card = styled.div<{ showFront: boolean }>`
  width: 100%;
  height: 194px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.8s;
  border-radius: 12px;

  transform: ${({ showFront }) =>
    showFront ? 'rotateY(0deg)' : 'rotateY(180deg)'};
`;

export const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: gray;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: #ababab;
  border-radius: 12px;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  padding: 1rem 2rem;
  border-radius: 20px;
  border: none;
  background-color: #f2f2f2;
  width: 100%;

  &::placeholder {
    font-weight: 500;
    opacity: 0.4;
    font-size: 12px;
  }

  &:hover {
    border: none;
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  }
`;
export const SelectWrapper = styled.div`
  padding: 1rem 1rem 1rem 2rem; // Ajuste o padding conforme necessário
  border-radius: 20px;
  background-color: #f2f2f2;
  width: 100%;
  overflow: hidden; // Importante para esconder a borda do select interno

  select {
    width: 110%; // Estende um pouco mais o select para a direita
    border: none;
    background: transparent;
    -webkit-appearance: none; // Remove a aparência padrão em navegadores Webkit
    padding-right: 1rem; // Reduz o padding à direita para ajustar a posição da seta

    &:focus {
      outline: none;
      box-shadow: none;
    }

    option {
      background: white; // Define a cor de fundo para as opções
    }
  }
`;
export const Select = styled.select`
  padding: 1rem 2rem;
  border-radius: 20px;
  border: none;
  background-color: #f2f2f2;
  width: 100%;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="%23000"><polygon points="0,0 20,0 10,10"/></svg>'); // SVG de seta customizada
  background-repeat: no-repeat;
  background-position: right 2rem center;
  background-size: 0.5em;

  &::placeholder {
    font-weight: 500;
    opacity: 0.4;
    font-size: 12px;
  }

  &:hover {
    border: none;
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  }
`;

export const Label = styled.label`
  color: gray;
  font-weight: 500;
  width: 100%;
`;

export const DoubledInput = styled.div`
  display: flex;
  gap: 1rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
