import React, { useContext, useEffect } from 'react';

import * as S from './styles';
import { common } from '../../../../styles/GlobalStyle/themes';
import { ThemeProvider } from 'styled-components';
import {
  INewStudentProvider,
  NewStudentProviderContext,
  themes,
} from '../../ProviderNewStudent';
import Loading from '../../../common/Loading';
import { Outlet } from 'react-router-dom';

export default function LayoutPayment() {
  const { student, loading, themeSelected, setThemeSelected } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  useEffect(() => {
    if (
      student?.preferences.theme &&
      student?.preferences.theme !== themeSelected
    ) {
      setThemeSelected(student?.preferences.theme);
    }
  }, [student?.preferences.theme]);

  return (
    <ThemeProvider theme={{ ...themes[themeSelected], ...common }}>
      {loading && <Loading />}
      <S.Container>
        <Outlet />
      </S.Container>
    </ThemeProvider>
  );
}
