import React from "react";
import { Navigate } from "react-router-dom";
import { authenticatedRequest } from "../../../services/api/student/api.service";

class Logout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null,
    };
  }
  logout = () => {
    localStorage.clear();
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    authenticatedRequest("/logout", "POST")
      .then(() => { })
      .catch(() => { });

    this.setState({ redirectTo: true });
    return;
  };

  render() {
    this.logout();
    return this.state.redirectTo ? <Navigate to="/new/aluno" /> : <></>;
  }
}

export default Logout;
