import React from 'react';

import * as S from './styles';

import {
  IPillarTcc,
  IRulesToSendTcc,
} from '../../../../../../../../../../services/newStudent/myCourses/myCourses.service';
import { CardLeftColor } from '../../../../../../../../Card';
import BlockIcon from '@mui/icons-material/Block';
import TaskAltSharpIcon from '@mui/icons-material/TaskAltSharp';
import { useTheme } from 'styled-components';
import ChartRadialBar from '../../../../../../../../../common/ChartRadialBar';

interface Props {
  rules?: IRulesToSendTcc;
  pillar?: IPillarTcc[];
}
export default function AcademicProjectPillar({ rules, pillar }: Props) {
  const theme = useTheme();

  return (
    <S.Container>
      <CardLeftColor color="danger" heightSize="fullHeight">
        <S.ContentInfo>
          <S.H4>{rules?.title} </S.H4>
          {rules?.structure?.map((item, index) => (
            <S.KeyValue key={index}>
              <S.Key>{item.title}: </S.Key>
              <S.Value>{item.text}</S.Value>
            </S.KeyValue>
          ))}
        </S.ContentInfo>
      </CardLeftColor>
      <S.CardContent>
        {pillar?.map((item: IPillarTcc, index: number) => (
          <S.Card key={index}>
            <S.Header completed={item.completed}>
              <S.ContentHeader>
                <div>
                  <S.H4Title>{item.title}</S.H4Title>
                  <S.H6Title>{item.subtitle}</S.H6Title>
                </div>
                {item.completed ? (
                  <TaskAltSharpIcon fontSize="large" />
                ) : (
                  <BlockIcon fontSize="large" />
                )}
              </S.ContentHeader>
            </S.Header>
            <S.Content>
              <S.Items>
                {item.structure.map((info, index) => (
                  <S.DivIconContent key={index}>
                    {info.completed === true ? (
                      <TaskAltSharpIcon
                        fontSize="medium"
                        style={{ color: theme.colors?.aqua }}
                      />
                    ) : info.completed === false ? (
                      <BlockIcon
                        fontSize="medium"
                        style={{ color: theme.colors?.danger }}
                      />
                    ) : (
                      <></>
                    )}
                    <S.KeyValueCard>
                      <S.H6 style={{ fontWeight: 'bold' }}>{info.title}</S.H6>
                      <S.H6>{info.value}</S.H6>
                    </S.KeyValueCard>
                  </S.DivIconContent>
                ))}
              </S.Items>
              {item.percent ? (
                <S.Chart>
                  <ChartRadialBar
                    color={
                      item.completed ? theme.colors?.aqua : theme.colors?.danger
                    }
                    value={item.percent}
                  />
                </S.Chart>
              ) : (
                <></>
              )}
            </S.Content>
          </S.Card>
        ))}
      </S.CardContent>
    </S.Container>
  );
}
