import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import * as S from './styles';
import { numberToReal } from '../../../../../../../util/agent/money';
import FormField from '../../../../../FormField';
import { ButtonSimple } from '../../../../../Buttons';
import { IIsItemsEmpty } from '../..';
import { INewNegotiationSale } from '../../../../../../../pages/agent/MyBusiness/components/ModalNewStudentData';
import { authenticatedRequest } from '../../../../../../../services/api/agent/api.agent.service';
import { successToast, warningToast } from '../../../../../../common/Toast';
import {
  IAgentContextProps,
  ProviderAgentContext,
} from '../../../../../ProviderAgent';
import {
  IDiscountSale,
  IFormDataStudent,
} from '../../../../../../../services/agent/student/interface';
import { isComboThreePosFixedNineteenParcels } from '../../../../../../../util/agent/rules';

export interface ICoupon {
  valueDiscount?: number;
  value?: number;
  text?: string;
}
interface Props {
  valueItems: { value: number; qtd: number; debt?: number };
  registration: { value: number };
  discount: IDiscountSale;
  valueTotal: number;
  isItemsEmpty: IIsItemsEmpty;
  negotiation: INewNegotiationSale;
  setNegotiation: Dispatch<SetStateAction<INewNegotiationSale>>;
  setDiscount: Dispatch<SetStateAction<IDiscountSale>>;
  setValueTotal: Dispatch<SetStateAction<number>>;
  coupon: ICoupon | null;
  setCoupon: Dispatch<SetStateAction<ICoupon>>;
  form: IFormDataStudent;
  idCardDiscount: number;
}

export default function SaleInformation({
  valueItems,
  registration,
  discount,
  valueTotal,
  isItemsEmpty,
  setValueTotal,
  setDiscount,
  setNegotiation,
  negotiation,
  setCoupon,
  coupon,
  idCardDiscount,
}: Props) {
  const context = useContext(ProviderAgentContext);
  const { isLoading, setIsLoading } = context as IAgentContextProps;

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const [optionsAgent, setOptionsAgent] = useState([
    { title: '[Selecione]', value: undefined },
  ]);

  /** Aplica o cupom de desconto */
  const handleApply = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (discount.coupon!.text) {
      const { data, result } = await authenticatedRequest(
        '/sales-agent/negotiation/coupon',
        'POST',
        {
          coupon: discount.coupon!.text,
        },
      );
      if (result) {
        setNegotiation((old) => ({
          ...old,
          discounts: {
            ...old.discounts,
            coupon: {
              valueDiscount: data.desconto / 100,
              text: discount.coupon!.text,
              value:
                (data.desconto / 100) * (valueItems.value - discount.value!),
            },
          },
        }));
        setCoupon(() => ({
          valueDiscount: data.desconto / 100,

          value: (data.desconto / 100) * (valueItems.value - discount.value!),
          text: discount.coupon!.text,
        }));
        setIsLoading(false);
        return successToast(`Cupom (${discount.coupon!.text}) aplicado`);
      } else {
        warningToast('Cupom de desconto não encontrado!');
      }
    }
    setCoupon({
      valueDiscount: 0,
      value: 0,
      text: undefined,
    });
    setIsLoading(false);
  };

  /** Aplica o calculo dos valores já inseridos
   * Exemplo: Valor total da venda, Matrícula, Desconto, etc.
   */
  const comboDiscount = negotiation?.discounts?.combo?.value || 0;
  const paymentTypeDiscount = negotiation?.discounts?.paymentType?.value || 0;
  const studentDiscount = negotiation?.discounts?.studentDiscount?.value || 0;

  const valueDiscounts =
    +comboDiscount + +paymentTypeDiscount + +studentDiscount;

  const valueFees =
    negotiation.fees?.interest?.value + negotiation?.fees?.penalty?.value;

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      let calcTotal = (valueItems && valueItems.value) || 0;
      let calcDiscount = 0;

      calcTotal = calcTotal - valueDiscounts;

      calcDiscount = calcDiscount + valueDiscounts;

      if (negotiation.discounts.coupon && negotiation.discounts.coupon.value) {
        calcTotal = calcTotal - calcTotal * coupon!.valueDiscount!;
      }

      if (
        JSON.stringify(valueDiscounts) !== JSON.stringify(calcDiscount) ||
        JSON.stringify(valueTotal) !== JSON.stringify(calcTotal) ||
        coupon!.value ||
        registration.value ||
        valueItems ||
        valueFees
      ) {
        setDiscount((old) => ({ ...old, value: calcDiscount }));
        setValueTotal(
          registration.value + (valueItems.debt || 0) + calcTotal + valueFees,
        );
      }
    }, 500);

    setTimer(newTimer);
    return () => {
      if (newTimer) {
        clearTimeout(newTimer);
      }
    };
  }, [
    negotiation.discounts,
    valueItems.value,
    valueItems.debt,
    registration.value,
    valueTotal,
    coupon!.valueDiscount!,
    valueFees,
  ]);

  /** Recalcula o desconto do cupom */
  useEffect(() => {
    setNegotiation((old) => ({
      ...old,
      discounts: {
        ...old.discounts,
        coupon: {
          ...old.discounts.coupon,
          value:
            (coupon! && coupon!.valueDiscount!) *
            (valueItems.value - discount.value!),
        },
      },
    }));
    setCoupon((old) => ({
      ...old,
      value:
        (old! && old!.valueDiscount!) * (valueItems.value - discount.value!),
    }));
  }, [valueItems.value, discount?.value]);

  /** Pega os dados do Agenciador */
  useEffect(() => {
    const opt = localStorage.getItem('agent');
    const data = JSON.parse(opt!).sales_agent || [];
    setOptionsAgent(() => [
      ...data.polos_gerenciados.map((agent) => {
        return { title: agent.name, value: agent.id };
      }),
    ]);
  }, []);

  /** Coloca o agenciador como o vendedor primário */
  useEffect(() => {
    if (!negotiation.sales_id) {
      return setNegotiation((old) => ({
        ...old,
        sales_id: optionsAgent[0].value,
      }));
    }
  }, [optionsAgent]);

  const subTotal = valueItems.value - valueDiscounts;

  useEffect(() => {
    if (isComboThreePosFixedNineteenParcels(idCardDiscount)) {
      setCoupon({
        valueDiscount: 0,
        value: 0,
        text: undefined,
      });
      setDiscount((old) => ({
        ...old,
        coupon: {
          valueDiscount: 0,
          value: 0,
          text: '',
        },
      }));
      setNegotiation((old) => ({
        ...old,
        discounts: {
          ...old.discounts,
          coupon: {
            valueDiscount: 0,
            value: 0,
            text: '',
          },
        },
      }));
    }
  }, [idCardDiscount]);

  return (
    <S.Container>
      <S.Title>Resumo</S.Title>
      <S.Content>
        <S.Item>
          <S.ItemDiv>
            <h6>Quantidade:</h6>
          </S.ItemDiv>
          <S.ItemDiv>
            <S.H6>{valueItems.qtd}</S.H6>
          </S.ItemDiv>
        </S.Item>
        <S.Item>
          <S.ItemDiv>
            <h6>Valor Itens:</h6>
          </S.ItemDiv>
          <S.ItemDiv>
            <S.H6>{numberToReal(valueItems.value)}</S.H6>
          </S.ItemDiv>
        </S.Item>
        <S.Item>
          <S.ItemDiv>
            <h6>Desconto:</h6>
          </S.ItemDiv>
          <S.ItemDiv>
            <S.H6>
              {numberToReal(+valueDiscounts >= 0 ? valueDiscounts! * -1 : 0)}
            </S.H6>
          </S.ItemDiv>
        </S.Item>
        <S.Item>
          <S.ItemDiv>
            <h6>Subtotal:</h6>
          </S.ItemDiv>
          <S.ItemDiv>
            <S.H6>{numberToReal(subTotal < 0 ? 0 : subTotal)}</S.H6>
          </S.ItemDiv>
        </S.Item>
        {valueFees ? (
          <S.Item>
            <S.ItemDiv>
              <h6>Juros:</h6>
            </S.ItemDiv>
            <S.ItemDiv>
              <S.H6>{numberToReal(valueFees!)}</S.H6>
            </S.ItemDiv>
          </S.Item>
        ) : null}
        {typeof coupon?.valueDiscount === 'number' &&
          coupon?.valueDiscount > 0 && (
            <S.Item style={{ paddingBottom: '1rem' }}>
              <S.ItemDiv>
                <h6>Cupom:</h6>
              </S.ItemDiv>
              <S.ItemDiv>
                <S.H6>{numberToReal(coupon.value! * -1)}</S.H6>
              </S.ItemDiv>
            </S.Item>
          )}
        <S.Item>
          <S.ItemDiv>
            <h6>Taxa/Matrícula:</h6>
          </S.ItemDiv>
          <S.ItemDiv>
            <S.H6>{numberToReal(registration.value)}</S.H6>
          </S.ItemDiv>
        </S.Item>
        {valueItems.debt ? (
          <S.Item>
            <S.ItemDiv>
              <h6>Saldo Devedor:</h6>
            </S.ItemDiv>
            <S.ItemDiv>
              <S.H6>{numberToReal(valueItems.debt)}</S.H6>
            </S.ItemDiv>
          </S.Item>
        ) : null}
        <S.Item style={{ paddingTop: '1rem' }}>
          <S.ItemDiv>
            <h6>Valor Total:</h6>
          </S.ItemDiv>
          <S.ItemDiv>
            <S.H6>{numberToReal(valueTotal >= 0 ? valueTotal : 0)}</S.H6>
          </S.ItemDiv>
        </S.Item>
      </S.Content>
      <S.Footer>
        <S.Cupom>
          <FormField
            item={{
              handleChange: (e) =>
                setDiscount((old) => ({
                  ...old,
                  coupon: {
                    ...old.coupon,
                    text: e.target.value,
                  },
                })),
              type: 'string',
              name: 'coupon',
              title: 'Cupom de Desconto',
              placeholder: 'Cupom'.toLocaleUpperCase(),
              value: discount?.coupon?.text && discount?.coupon?.text,
              disabled:
                isItemsEmpty.info ||
                negotiation.discounts.coupon?.value !== 0 ||
                isComboThreePosFixedNineteenParcels(idCardDiscount) ||
                subTotal <= 0,
            }}
          />
          {negotiation.discounts.coupon?.value !== 0 ? (
            <ButtonSimple
              color="white"
              size="medium"
              onClick={(e) => {
                e.preventDefault();
                setCoupon({
                  valueDiscount: 0,
                  value: 0,
                  text: undefined,
                });
                setDiscount((old) => ({
                  ...old,
                  coupon: {
                    valueDiscount: 0,
                    value: 0,
                    text: '',
                  },
                }));
                setNegotiation((old) => ({
                  ...old,
                  discounts: {
                    ...old.discounts,
                    coupon: {
                      valueDiscount: 0,
                      value: 0,
                      text: '',
                    },
                  },
                }));
              }}
              disabled={
                isItemsEmpty.info ||
                isComboThreePosFixedNineteenParcels(idCardDiscount)
              }
            >
              Limpar
            </ButtonSimple>
          ) : (
            <ButtonSimple
              color="green"
              size="medium"
              onClick={(e) => handleApply(e)}
              disabled={
                isItemsEmpty.info ||
                isLoading ||
                subTotal <= 0 ||
                isComboThreePosFixedNineteenParcels(idCardDiscount)
              }
              type="submit"
            >
              Aplicar
            </ButtonSimple>
          )}
        </S.Cupom>
        <div>
          <FormField
            item={{
              title: 'Vendedor',
              value: negotiation.sales_id,
              handleChange: (e) => {
                setNegotiation((old) => ({
                  ...old,
                  sales_id: e.target.value,
                }));
              },
              disabled: isItemsEmpty.info,
              type: 'select',
              options: optionsAgent,
            }}
          />
        </div>
      </S.Footer>
    </S.Container>
  );
}
