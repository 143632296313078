import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Footer, SideBar, Header, Main } from '../components';

import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../../../../styles/GlobalStyle/global';
import { common } from '../../../../styles/GlobalStyle/themes';
import { Box } from '@mui/material';

import * as S from './style';
import { Outlet } from 'react-router-dom';
import Loading from '../../../common/Loading';
import {
  INewStudentProvider,
  NewStudentProviderContext,
  themes,
} from '../../ProviderNewStudent';
import LoginAs from '../components/LoginAs';
import { nodeEnv } from '../../../../variables';
import ChatFloating from '../components/ChatFloating';

export default function LayoutMainNewStudent() {
  const {
    student,
    loading,
    isSidebarOpen,
    themeSelected,
    accessedAs,
    setThemeSelected,
  } = useContext(NewStudentProviderContext) as INewStudentProvider;

  const [isAccessibilityOpen, setIsAccessibilityOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      student?.preferences.theme &&
      student?.preferences.theme !== themeSelected
    ) {
      setThemeSelected(student?.preferences.theme);
    }
  }, [student?.preferences.theme]);

  /** Isso aqui vai sair assim que alterarmos pro novo portal do aluno */
  const shouldBackgroundSolid = useMemo(() => {
    const referrer = document.referrer;

    if (referrer) {
      const referrerUrl = new URL(referrer);

      if (
        referrerUrl.pathname.startsWith('/aluno') &&
        window.location.pathname.startsWith('/new/aluno')
      ) {
        return true;
      } else return false;
    }
  }, []);

  return (
    <ThemeProvider theme={{ ...themes[themeSelected], ...common }}>
      {loading && <Loading isBackgroundSolid={shouldBackgroundSolid} />}
      {accessedAs && <LoginAs />}
      <S.Container>
        <GlobalStyle />
        <S.Main className="main">
          <SideBar setIsAccessibilityOpen={setIsAccessibilityOpen} />
          <Box
            sx={{
              display: isSidebarOpen ? 'none' : 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
            }}
          >
            <Header
              themeSelected={themeSelected}
              setThemeSelected={setThemeSelected}
              setOpen={setIsAccessibilityOpen}
              open={isAccessibilityOpen}
            />
            <Main>
              <Outlet />
            </Main>
          </Box>
        </S.Main>
        <Footer />
      </S.Container>
      {nodeEnv === 'production' && (
        <ChatFloating
          student={true}
        />
      )}
    </ThemeProvider>
  );
}
