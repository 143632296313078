import React, { useContext, useEffect, useState } from 'react';
import * as S from './styles';
import { Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { financialServiceNewStudent } from '../../../services/newStudent';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';
import { ButtonSimple } from '../../../components/newStudent/Buttons';
import { successToast } from '../../../components/common/Toast';
import { sieadImagesFolderUrl } from '../../../variables';

export default function Payment() {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [showFront, setShowFront] = useState(true);
  const [cardNumber, setCardNumber] = useState('**** **** **** ****');
  const [cardCvv, setCardCvv] = useState('');
  const [cardName, setCardName] = useState('');
  const [parcelQtd, setParcelQtd] = useState<number | string>('1');
  const [paymentId, setPaymentId] = useState<number | null>();
  const [cardExpirationDate, setCardExpirationDate] = useState('');
  const [maxParcels, setMaxParcels] = useState(1);
  const [cardImage, setCardImage] = useState('');

  const [userData, setUserData] = useState({
    name: '',
    cpf: '',
    email: '',
    value: '',
    description: '',
    qr: '',
    qrCodeCopy: '',
  });

  const brands = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    'american-express': /^3[47][0-9]{13}$/,
    elo: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
    hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
  };

  const handleCardNumberChange = (e) => {
    let input = e.target.value.replace(/\D/g, '');
    input = input.slice(0, 16);
    const maskedInput = input.padEnd(16, '*');

    const formattedNumber = maskedInput.replace(/(.{4})/g, '$1 ').trim();

    setCardNumber(formattedNumber);

    const brandKeys = Object.keys(brands);
    let matched = false;

    for (let i = 0; i < brandKeys.length; i++) {
      const brand = brandKeys[i];
      if (brands[brand].test(input)) {
        setCardImage(`/assets/icons/${brand}.png`);
        matched = true;
        break;
      }
    }

    if (!matched) {
      setCardImage('');
    }
  };

  const handleCardDueChange = (e) => {
    let input = e.target.value.replace(/\D/g, '').slice(0, 4);
    if (input.length >= 2) {
      input = `${input.slice(0, 2)}/${input.slice(2)}`;
    }
    setCardExpirationDate(input);
  };

  const handleCardCvvChange = (e) => {
    const input = e.target.value.replace(/\D/g, '').slice(0, 4);
    setCardCvv(input);
  };
  const handleNameChange = (e) => {
    setShowFront(true);
    setCardName(e.target.value);

    // Tirei por que não precisa mais

    // const nameParts = e.target.value.split(' ');
    // const firstName = nameParts[0];
    // const lastName =
    //   nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';

    // if (nameParts.length === 3 && nameParts[1].length <= 3) {
    //   setCardName(`${firstName} ${lastName}`);
    // } else if (nameParts.length > 2) {
    //   const secondNameInitial = nameParts[1][0];
    //   setCardName(`${firstName} ${secondNameInitial} ${lastName}`);
    // } else {
    //   setCardName(`${firstName} ${lastName}`);
    // }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const fetchPaymentData = async () => {
      setIsLoading(true);
      try {
        const id = queryParams.get('paymentId');

        if (id) {
          setPaymentId(+id);
          const { data, result } =
            await financialServiceNewStudent.getPaymentData(+id);
          setMaxParcels(data.maxParcels);

          setUserData({
            value: data.amount,
            name: data.name,
            cpf: data.cpf,
            email: data.email,
            description: data.description,
            qr: data.pixQrcode,
            qrCodeCopy: data.pixKey,
          });
          if (result) {
            if (data) {
              queryParams.delete('paymentId');
              navigate({ search: queryParams.toString() }, { replace: true });
            }
          }
        }
      } catch (error) {
        throw new Error(String(error));
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentData();
  }, [location.search]);

  const handleSubmit = async () => {
    const { result } = await financialServiceNewStudent.makePayment(
      {
        cardName: cardName,
        cardExpirationDate: cardExpirationDate,
        cardNumber: cardNumber,
        cvv: cardCvv,
        installments: parcelQtd,
      },
      paymentId,
    );
    if (result) {
      setTimeout(() => {
        navigate('/new/aluno/historico-financeiro');
      }, 3000);
    }
  };

  return (
    userData.value && (
      <S.Container>
        <S.Content>
          <S.Header>
            <S.HeaderTitle>
              <S.TitleDiv>
                <S.Dot></S.Dot>
                <S.Title>Pagamento</S.Title>
              </S.TitleDiv>
              <S.ImageLogo
                src={sieadImagesFolderUrl + 'grupo-fontella-logo.png'}
              />
            </S.HeaderTitle>
            <S.Subtitle>
              <div dangerouslySetInnerHTML={{ __html: userData.description }} />
            </S.Subtitle>
            <S.KeyValue>
              <S.Key>Nome:</S.Key>
              <S.Value>{userData.name}</S.Value>
            </S.KeyValue>
            <S.KeyValue>
              <S.Key>CPF:</S.Key>
              <S.Value>{userData.cpf}</S.Value>
            </S.KeyValue>
            <S.KeyValue>
              <S.Key>E-mail:</S.Key>
              <S.Value>{userData.email}</S.Value>
            </S.KeyValue>
            <S.KeyValue>
              <S.Key>Valor:</S.Key>
              <S.Value>
                {Number(userData.value).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </S.Value>
            </S.KeyValue>
          </S.Header>
          {userData.qr ? (
            <S.CardForm>
              <img src={userData.qr} />
              <S.KeyValue>
                <S.Key>{userData.qrCodeCopy}</S.Key>
              </S.KeyValue>
              <S.KeyValue>
                <ButtonSimple
                  color="primary"
                  onClick={async () => {
                    await navigator.clipboard.writeText(userData.qrCodeCopy);
                    successToast(
                      'QR Code copiado para a área de transferência!',
                    );
                  }}
                >
                  Copiar
                </ButtonSimple>
              </S.KeyValue>
            </S.CardForm>
          ) : (
            <S.CardForm>
              <S.PaymentTypeDiv>
                <S.Checkbox></S.Checkbox>
                <S.PaymentType>Cartão de crédito</S.PaymentType>
              </S.PaymentTypeDiv>
              <S.CardContainer>
                <S.Card showFront={showFront}>
                  <S.CardFront>
                    <S.Chip src="/assets/icons/chip.png" />
                    <S.CardNumber>{cardNumber}</S.CardNumber>
                    <S.CardName>{cardName}</S.CardName>
                  </S.CardFront>
                  <S.CardBack>
                    <S.Line>.</S.Line>
                    <S.KeyValue>
                      <S.CardTitleDue>Venc:</S.CardTitleDue>
                      <S.CardDue>{cardExpirationDate}</S.CardDue>
                    </S.KeyValue>
                    <S.KeyValue>
                      <S.CardTitleCvv>CVV</S.CardTitleCvv>
                      <S.CardCVV>{cardCvv}</S.CardCVV>
                    </S.KeyValue>
                    {cardImage && <S.TypeCartImg src={cardImage} />}
                  </S.CardBack>
                </S.Card>
              </S.CardContainer>

              <Formik initialValues={{}} onSubmit={() => {}} enableReinitialize>
                {({ handleChange }) => (
                  <S.Form>
                    <S.InputContainer>
                      <S.Label htmlFor="credit-card-number">
                        Número do cartão
                      </S.Label>
                      <S.Input
                        placeholder="1234 1234 1234 1234"
                        type="text"
                        onKeyPress={(e) => {
                          if (
                            !/[0-9]/.test(e.key) &&
                            e.key !== 'Backspace' &&
                            e.key !== 'Tab'
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          let inputValue = e.target.value.replace(/\D/g, '');
                          setShowFront(true);

                          if (/^[0-9]*$/.test(inputValue)) {
                            inputValue = inputValue
                              .replace(/(.{4})/g, '$1 ')
                              .trim();

                            e.target.value = inputValue;
                            handleCardNumberChange(e);
                            handleChange(e);
                          }
                        }}
                        maxLength={19}
                      />
                    </S.InputContainer>

                    <S.InputContainer>
                      <S.Label htmlFor="card-number">Nome no cartão</S.Label>
                      <S.Input
                        placeholder="Nome"
                        type="text"
                        onChange={handleNameChange}
                      />
                    </S.InputContainer>
                    <S.DoubledInput>
                      <S.InputContainer>
                        <S.Label htmlFor="credit-card-number">Validade</S.Label>
                        <S.Input
                          placeholder="MM/AA"
                          type="text"
                          onChange={(e) => {
                            handleCardDueChange(e);
                            handleChange(e);
                            setShowFront(false);
                          }}
                          value={cardExpirationDate}
                        />
                      </S.InputContainer>
                      <S.InputContainer>
                        <S.Label htmlFor="credit-card-number">
                          Cód. de segurança
                        </S.Label>
                        <S.Input
                          placeholder="000"
                          type="text"
                          maxLength={3}
                          onChange={(e) => {
                            handleCardCvvChange(e);
                            handleChange(e);
                            setShowFront(false);
                          }}
                          value={cardCvv}
                        />
                      </S.InputContainer>
                    </S.DoubledInput>
                    <S.InputContainer>
                      <S.Label htmlFor="parcel-number">
                        Número de Parcelas
                      </S.Label>
                      <S.Select
                        id="parcel-number"
                        value={parcelQtd}
                        onChange={(e) => setParcelQtd(e.target.value)}
                      >
                        {Array.from({ length: maxParcels }, (_, index) => {
                          const parcelNumber = index + 1;

                          const parcelValue = (
                            Number(userData.value) / parcelNumber
                          ).toFixed(2);
                          return (
                            <option key={parcelNumber} value={parcelNumber}>
                              {parcelNumber}{' '}
                              {parcelNumber === 1 ? 'Parcela' : 'Parcelas'} de
                              R${parcelValue}
                            </option>
                          );
                        })}
                      </S.Select>
                    </S.InputContainer>
                    <S.KeyValue>
                      <ButtonSimple
                        color="success"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Pagar
                      </ButtonSimple>
                    </S.KeyValue>
                  </S.Form>
                )}
              </Formik>
            </S.CardForm>
          )}
        </S.Content>
      </S.Container>
    )
  );
}
