import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import { useTheme } from 'styled-components';

import * as S from './styles';
import WarningIcon from '@mui/icons-material/Warning';
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';

import { ticketsServiceNewStudent } from '../../../../../services/newStudent';

import { ITicket } from '../..';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import { AlertBar } from '../../../../../components/newStudent/Alert';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import TableBasic from '../../../../../components/newStudent/Table';
import { handleMaxWidth } from '../../../../../util/newStudent';
import TicketDescription from '../TicketDescription';
import { formatDateHour } from '../../../../../util/common/appUtil';
import { PrintOutlined } from '@mui/icons-material';

interface IAction {
  id: number;
  businessName: string;
  createdDate: string;
  description: string;
  attachments: { fileName: string; link: string; number: number }[] | [];
}

export interface ITicketSimple {
  status: string;
  protocol: string;
  actions: IAction[];
}

interface Props {
  item: ITicket;
  index: number;
  indexOpen: number | undefined;
  setIndexOpen: Dispatch<SetStateAction<number | undefined>>;
}
export default function TicketItem({
  item,
  index,
  indexOpen,
  setIndexOpen,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [ticket, setTicket] = useState<ITicketSimple>();
  const theme = useTheme();

  const handleOpen = async (find: number, index: number) => {
    if (indexOpen === index) {
      return setIndexOpen(undefined);
    }
    setIsLoading(true);

    try {
      setIndexOpen(index);
      const fetch = async () => {
        const { result, data } = await ticketsServiceNewStudent.find(find);
        if (result) {
          setTicket(data);
        }
        setIsLoading(false);
      };
      fetch();
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  /** Enum dos status vindos do movidesk */
  const statusEnum = {
    Cancelado: {
      status: 'Cancelado',
      buttonColor: 'danger',
      iconColor: 'white',
      alertColor: 'danger',
      icon: <WarningIcon fontSize="large" />,
    },
    Resolvido: {
      status: 'Resolvido',
      buttonColor: 'success',
      iconColor: 'success',
      alertColor: 'white',
      icon: <DoneIcon fontSize="large" />,
    },
    'Fechado pelo Atendente': {
      status: 'Fechado',
      buttonColor: 'success',
      iconColor: 'success',
      alertColor: 'white',
      icon: <DoneIcon fontSize="large" />,
    },
    Fechado: {
      status: 'Fechado',
      buttonColor: 'success',
      iconColor: 'success',
      alertColor: 'white',
      icon: <DoneIcon fontSize="large" />,
    },
    'Em Andamento': {
      status: 'Em Andamento',
      buttonColor: 'gray',
      iconColor: 'warning',
      alertColor: 'warning',
      icon: <SearchIcon fontSize="large" />,
    },
    'Em atendimento': {
      status: 'Em Atendimento',
      buttonColor: 'gray',
      iconColor: 'warning',
      alertColor: 'warning',
      icon: <SearchIcon fontSize="large" />,
    },
    Novo: {
      status: 'Novo',
      buttonColor: 'gray',
      iconColor: 'white',
      alertColor: 'white',
      icon: <SearchIcon fontSize="large" />,
    },
  };

  const tableData = {
    title: ['Situação', 'Movimentação', 'Protocolo nº'],
    content: ticket?.actions.map((item, index) => ({
      Situação: {
        item: index === 0 && (
          <S.RowColor
            color={ticket?.status ? statusEnum[ticket?.status].buttonColor : ''}
          >
            {statusEnum[ticket?.status].status}
          </S.RowColor>
        ),
      },
      Movimentação: {
        item: (
          <S.ContentMovimentation>
            <h6>
              Movimentação n°{index + 1} incluída por {item.businessName} no dia{' '}
              {formatDateHour(item.createdDate)}
            </h6>
            {item.attachments.length > 0 &&
              item.attachments.map((attachments) => (
                <S.DivAnexos key={attachments.number}>
                  <S.H6>{attachments.fileName}</S.H6>
                  <ButtonSimple
                    color="success"
                    heightSize="small"
                    widthSize="small"
                    onClick={() => window.open(attachments.link)}
                  >
                    <PrintOutlined fontSize="large" />
                  </ButtonSimple>
                </S.DivAnexos>
              ))}
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
          </S.ContentMovimentation>
        ),
      },
      'Protocolo nº': {
        item: index === 0 && (
          <S.RowColor
            color={ticket?.status ? statusEnum[ticket?.status].buttonColor : ''}
          >
            {ticket?.protocol}
          </S.RowColor>
        ),
      },
    })),
  };
  return (
    <S.Container>
      <AlertBar
        noWarning
        noWarningIcon
        pointer
        onClick={() => handleOpen(item.id, index)}
        color={statusEnum[item.status].alertColor}
        text={<S.H6Break>{item.subject}</S.H6Break>}
        icon={statusEnum[item.status].icon}
        iconColor={statusEnum[item.status].iconColor}
        flexDirection={handleMaxWidth(theme.size?.medium) ? 'column' : 'row'}
        button={
          <S.Buttons>
            <ButtonSimple
              color={statusEnum[item.status].buttonColor}
              outline
              noButton
              heightSize="small"
              widthSize="large"
            >
              {statusEnum[item.status].status}
            </ButtonSimple>
            <div color={theme.colors?.gray03}>
              {index === indexOpen && ticket ? (
                <img src="/assets/icons/arrowUpDisable.png" width="20px" />
              ) : (
                <img src="/assets/icons/arrowDownDisable.png" width="20px" />
              )}
            </div>
          </S.Buttons>
        }
      />
      {index === indexOpen && ticket && (
        <S.ContainerFocus>
          {handleMaxWidth(theme.size?.medium) ? (
            <TicketDescription
              ticket={ticket}
              color={statusEnum[item.status].buttonColor}
            />
          ) : (
            <TableBasic data={tableData} />
          )}
        </S.ContainerFocus>
      )}
    </S.Container>
  );
}
