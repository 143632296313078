import { INewNegotiationSale } from '../../../pages/agent/MyBusiness/components/ModalNewStudentData';
import { defaultReturn } from '../../../util/newStudent';
import { authenticatedRequest } from '../../api/agent/api.agent.service';
import {
  IConsultDiscount,
  IDiscountItem,
  IFilterFields,
  IFiltered,
  IStudentData,
} from './interface';

export const saleNegotiationService = {
  async createNegotiation(data: INewNegotiationSale) {
    const request = await authenticatedRequest(
      '/sales-agent/negotiation/create',
      'POST',
      data,
    );
    return defaultReturn(request);
  },

  async consultByType(itemValue, typeValue, dontSearch, search) {
    const request = await authenticatedRequest(
      '/helper/sale/consult-by-type',
      'POST',
      {
        item: itemValue,
        type: typeValue,
        selectedItems: dontSearch,
        login: search,
      },
    );
    return request;
  },

  async consultType(item: number | string) {
    const request = await authenticatedRequest(
      '/helper/sale/consult-type',
      'POST',
      {
        item: item,
      },
    );
    return request;
  },

  async consultPaymentType() {
    const request = await authenticatedRequest(
      '/helper/financial/consult-payment-type',
    );

    return request;
  },

  async consultDiscount(
    list: IConsultDiscount[],
    isStudent: boolean,
  ): Promise<{
    data: IDiscountItem[];
    message: string[];
    result: boolean;
  }> {
    const request = await authenticatedRequest(
      '/sales-agent/negotiation/consult-discount',
      'POST',
      { courses: list, isStudent: isStudent },
    );
    return request;
  },

  async filterSales(
    body: IFilterFields,
    dontToast?: boolean,
  ): Promise<{ data: IFiltered[]; result: boolean; message: string[] }> {
    const request = await authenticatedRequest(
      '/sales-agent/negotiation/show',
      'POST',
      body,
    );
    const result = dontToast ? request : defaultReturn(request);
    return result;
  },

  async editNegotiation(negotiation: INewNegotiationSale, id: number) {
    const request = await authenticatedRequest(
      `/sales-agent/negotiation/edit/${id}`,
      'POST',
      negotiation,
    );
    return defaultReturn(request);
  },

  async getEditNegotiation(id: number): Promise<{
    data: INewNegotiationSale;
    message: string[];
    result: boolean;
  }> {
    const request = await authenticatedRequest(
      `/sales-agent/negotiation/edit/${id}`,
      'GET',
    );
    return defaultReturn(request);
  },

  async consultStudent(body: { login: string }): Promise<{
    data: IStudentData;
    message: string[];
    result: boolean;
  }> {
    const request = await authenticatedRequest(
      `/sales-agent/negotiation/consult-student`,
      'POST',
      body,
    );
    return defaultReturn(request);
  },
};
