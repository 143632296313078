import React, { useContext, useEffect, useMemo, useState } from 'react';

import BasicModal from '../../../../../../../components/agent/Modal';
import HeaderModal from '../../../../../../../components/agent/HeaderModal';
import FooterModal from '../../../../../../../components/agent/FooterModal';
import BasicTab from '../../../../../../../components/agent/BasicTab';
import {
  TabListCourses,
  TabSale,
} from '../../../../../../../components/agent/Tabs/TabNegotiation';
import {
  TabDataStudent,
  TabPreviousSale,
} from '../../../../../../../components/agent/Tabs/TabNegotiation';

import { ButtonOpenTable } from '../../../../../../../components/agent/Buttons';
import {
  IDiscountSale,
  IIsEditSale,
  IItem,
  IFormDataStudent,
  IPayment,
  ICourseData,
} from '../../../../../../../services/agent/student/interface';
import {
  INewNegotiationSale,
  defaultFormNegotiation,
  defaultNegotiation,
} from '../../../ModalNewStudentData';
import { ICoupon } from '../../../../../../../components/agent/Tabs/TabNegotiation/TabSale/components/SaleInformation';
import {
  IAgentContextProps,
  ProviderAgentContext,
} from '../../../../../../../components/agent/ProviderAgent';
import { errorToast } from '../../../../../../../components/common/Toast';
import {
  coursesWithoutDiscounts,
  courseTypeFordoc,
  saleItemTypeCourse,
  saleItemTypeService,
  typeServiceCancel,
  typeServiceRegistrationRenewal,
} from '../../../../../../../variables';
import { getPhone } from '../../../../../../../util/common/appUtil';
import { IDiscountItem } from '../../../../../../../services/agent/sale/interface';
import { saleNegotiationService } from '../../../../../../../services/agent';

export default function ModalEdit({
  isOpen,
  setIsOpen,
  infoEdit,
  setInfoEdit,
}) {
  const context = useContext(ProviderAgentContext);
  const { setIsLoading } = context as IAgentContextProps;

  /** Criação das variáveis para usar nos componentes a seguir */
  const [form, setForm] = useState<IFormDataStudent>(defaultFormNegotiation);
  const [verifyFields, setVerifyFields] = useState<IFormDataStudent>(
    defaultFormNegotiation,
  );
  const [isEditSale, setIsEditSale] = useState<IIsEditSale>({ isFirst: true });

  const defaultPayment = {
    registration: {
      value: undefined,
      paymentType: undefined,
      dueDate: undefined,
      unified: false,
      registrationFree: false,
    },
    studentPlan: {
      studentPlanFree: false,
      paymentType: undefined,
      numberParcels: 0,
      dueDate: undefined,
      total: 0,
    },
  };
  const [negotiation, setNegotiation] =
    useState<INewNegotiationSale>(defaultNegotiation);
  const couponDefault = {
    valueDiscount: negotiation.discounts.coupon?.valueDiscount,
    value: negotiation.discounts.coupon?.value,
    text: '',
  };
  const [coupon, setCoupon] = useState<ICoupon | null>(couponDefault);

  const value = {
    id: undefined,
    value: '0',
    maxValue: '0',
  };

  const [valueTotal, setValueTotal] = useState(0);
  const [payment, setPayment] = useState<IPayment>(defaultPayment);
  const [discount, setDiscount] = useState<IDiscountSale>({
    studentDiscount: undefined,
    combo: undefined,
    coupon: { value: 0, text: '' },
    paymentType: undefined,
  });

  const [valueItems, setValueItems] = useState(0);

  const [send, setSend] = useState(false);

  const [items, setItems] = useState<IItem[] | []>([]);
  const [idCardDiscount, setIdCardDiscount] = useState(0);
  const [cardDiscount, setCardDiscount] = useState<IDiscountItem[]>([]);

  const [paymentTypeValue, setPaymentTypeValue] = useState([
    {
      id: 0,
      parcels: [{ quantity: 0, discount: 0, interest: 0, penalty: 0 }],
      description: '',
    },
  ]);

  /** Salva a Edição */

  const handleSave = async () => {
    setSend(true);
    try {
      setIsLoading(true);

      const negotiationTmp = { ...negotiation };

      const organize: string[] = [];
      for (const key in negotiationTmp.items) {
        if (
          +negotiation.items[key].itemType! === +saleItemTypeCourse! ||
          (+negotiation.items[key].itemType! === +saleItemTypeService! &&
            (+negotiation.items[key].type! ===
              +typeServiceRegistrationRenewal! ||
              +negotiation.items[key].type! === +typeServiceCancel!))
        ) {
          organize.push('1');
        }
      }

      if (organize.length <= 0) {
        return errorToast(
          'Para realizar a venda, é necessário inserir um curso',
        );
      }
      for (const key in negotiationTmp.dataStudent) {
        if (negotiationTmp.dataStudent[key] === undefined) {
          negotiationTmp.dataStudent[key] = null;
        }
      }
      for (const key in negotiation.registration) {
        if (negotiation.registration[key] === undefined) {
          negotiationTmp.registration[key] = null;
        }
      }
      for (const key in negotiation.studentPlan) {
        if (negotiation.studentPlan[key] === undefined) {
          negotiationTmp.studentPlan[key] = null;
        }
      }

      const { result } = await saleNegotiationService.editNegotiation(
        negotiationTmp,
        infoEdit.id,
      );
      if (result) {
        return handleClean();
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setSend(false);
      setIsLoading(false);
    }
  };

  const maxStudentValue = useMemo(() => {
    if (form.percentStudentDiscount && items.length > 0) {
      let valueServico = 0;
      let valueDecrease = 0;

      if (form.dataLocal.toUpperCase() === 'IPB') {
        for (const item of items) {
          valueDecrease =
            +item.type.value === +courseTypeFordoc!
              ? valueDecrease + +item.value.value!
              : valueDecrease;
        }
      }

      for (const item of items) {
        if (+item.item.value === +saleItemTypeService!) {
          valueServico = +valueServico + +value.value;
        }
      }

      const engineering = coursesWithoutDiscounts!.includes(',')
        ? coursesWithoutDiscounts!.split(',')
        : [coursesWithoutDiscounts];
      if (engineering!.length > 0 && engineering![0] !== '') {
        for (const item of items) {
          for (const eng of engineering!) {
            valueDecrease =
              +item.description.value === +eng!
                ? valueDecrease + (+item.value.value! - +item.discount.value!)
                : valueDecrease;
          }
        }
      }

      const maxValue = (
        (form.percentStudentDiscount! / 100) *
        (valueItems - valueDecrease - valueServico)
      ).toFixed(2);

      setNegotiation((old) => ({
        ...old,
        discounts: {
          ...old.discounts,
          studentDiscount: {
            ...old.discounts.studentDiscount,
            maxValue: maxValue,
          },
        },
      }));
      return +maxValue > 1 ? +maxValue : 0.001;
    }

    return 0.001;
  }, [form?.percentStudentDiscount, items, valueItems]);

  const maxComboValue = useMemo(() => {
    if (cardDiscount && cardDiscount.length > 0 && idCardDiscount) {
      const selected = cardDiscount.filter((item) => {
        return item?.discountId === idCardDiscount;
      });
      setNegotiation((old) => ({
        ...old,
        discounts: {
          ...old.discounts,
          combo: {
            ...old.discounts.combo,
            id: selected[0]?.discountId,
            maxValue: selected[0]?.maxDiscount.toString(),
          },
        },
      }));
      setDiscount((old) => ({
        ...old,
        combo: { ...old.combo, id: selected[0]?.discountId },
      }));

      return selected[0]?.maxDiscount!.toFixed(2);
    }

    return 0.001;
  }, [idCardDiscount, items, cardDiscount]);

  const maxPaymentTypeValue = useMemo(() => {
    if (paymentTypeValue.length > 0) {
      const filter = paymentTypeValue.find((item) => {
        return item.id === +negotiation.studentPlan.paymentType!;
      });
      if (filter) {
        const selectedParcel = filter.parcels.find(
          (item) => +item.quantity! === +negotiation.studentPlan.numberParcels!,
        );
        if (selectedParcel) {
          const studentDiscountValue =
            negotiation.discounts.studentDiscount?.value ?? 0;
          const comboValue = negotiation.discounts.combo?.value ?? 0;

          const discountsCalc = +studentDiscountValue + +comboValue;

          if (selectedParcel.interest || selectedParcel.penalty) {
            setNegotiation((old) => ({
              ...old,
              discounts: {
                ...old.discounts,
                paymentType: {
                  value: '0',
                  maxValue: '0',
                },
              },
              fees: {
                interest: {
                  value: selectedParcel.interest
                    ? ((valueItems - discountsCalc) * selectedParcel.interest) /
                      100
                    : 0,
                },
                penalty: {
                  value: selectedParcel.penalty
                    ? ((valueItems - discountsCalc) * selectedParcel.penalty) /
                      100
                    : 0,
                },
              },
            }));

            return 0.001;
          }

          const maxValue = (
            (selectedParcel!.discount! / 100) *
            (valueItems - discountsCalc)
          ).toFixed(2);
          setNegotiation((old) => ({
            ...old,
            discounts: {
              ...old.discounts,
              paymentType: {
                ...old.discounts.paymentType,
                maxValue: maxValue,
              },
            },
            fees: {
              interest: { value: 0 },
              penalty: { value: 0 },
            },
          }));
          return +maxValue > 0 ? +maxValue : 0.001;
        }
      }
    }
    return 0.001;
  }, [
    negotiation.studentPlan,
    valueItems,
    negotiation.discounts.combo?.value,
    negotiation.discounts.studentDiscount?.value,
    paymentTypeValue,
  ]);

  useEffect(() => {
    if (items.length > 0) {
      const fetchData = async () => {
        const { result, data } =
          await saleNegotiationService.consultPaymentType();

        return result && setPaymentTypeValue(data);
      };

      fetchData();
    }
  }, [items]);

  useEffect(() => {
    if (items.length <= 0) {
      setPaymentTypeValue([]);
      return setCardDiscount([]);
    }
    const fetch = async () => {
      const list = items
        .filter((item: IItem) => +item?.item?.value === 1)
        .map((item: IItem) => {
          return {
            id: +item.description.value,
            discount: +item.discount.value,
          };
        });

      const { data, result } = await saleNegotiationService.consultDiscount(
        list,
        form.dataStudent.isStudent,
      );

      if (result) {
        setCardDiscount(() => {
          return data.map((item) => ({
            discountId: item.discountId,
            type: item.type,
            discountDescription: item.discountDescription,
            percentage: item.percentage,
            maxDiscount: item.maxDiscount,
          }));
        });
      } else {
        setNegotiation((old) => ({
          ...old,
          discounts: { ...old.discounts, combo: value },
        }));
        setCardDiscount([]);
      }
    };

    if (items.length > 0) {
      fetch();

      let value = 0;

      items.map((i) => {
        if (+i.item.value === +saleItemTypeService!) {
          return;
        }
        value = value + (i.value.value - i.discount.value);
      });
      setValueItems(value);
    }
  }, [items, negotiation.dataStudent]);

  useEffect(() => {
    if (!isOpen) {
      setInfoEdit(undefined);
      setForm(defaultFormNegotiation);
      return;
    }
    try {
      setIsLoading(true);
      const fetchNegotiationEdit = async () => {
        const { result, data, message } =
          await saleNegotiationService.getEditNegotiation(infoEdit.id);

        if (result) {
          setForm((old) => ({
            ...old,
            dataStudent: {
              ...old.dataStudent,
              isStudent: data.dataStudent.isStudent,
            },
          }));

          const oldItems: IItem[] = data.items.map((item) => ({
            item: {
              value: String(item.itemType),
              title: item.itemName,
              options:
                item.itemName && item.itemType
                  ? [{ title: item.itemName, value: item.itemType }]
                  : [],
            },
            type: {
              title: String(item.typeName),
              value: String(item.type),
              options: [],
            },
            description: {
              value: String(item.id),
              title: String(item.name),
              options: [],
            },
            value: {
              value: item.value !== undefined ? Number(item.value) : 0,
            },
            discount: {
              value: item.discount !== undefined ? item.discount : 0,
            },
          }));

          setItems(oldItems);
          setPayment({
            registration: data.registration,
            studentPlan: data.studentPlan,
          });
          const discountCalc =
            (Number(data.discounts!.combo!.value!) !== undefined
              ? Number(data.discounts!.combo!.value!)
              : 0) +
            (Number(data.discounts!.studentDiscount!.value!) !== undefined
              ? Number(data.discounts!.studentDiscount!.value!)
              : 0) +
            (Number(data.discounts!.paymentType!.value!) !== undefined
              ? Number(data.discounts!.paymentType!.value!)
              : 0);

          setDiscount({ ...data.discounts, value: discountCalc });
          setIdCardDiscount(data.discounts!.combo!.id!);
          setCoupon(data.discounts!.coupon!);
          setNegotiation(data);
        } else {
          setIsOpen(false);
          return message.forEach((text) => {
            return errorToast(text);
          });
        }
      };
      const fetchDataStudent = async () => {
        const { data } = await saleNegotiationService.consultStudent({
          login: infoEdit.cpf,
        });

        if (data.student) {
          const newForm = {
            ...form,
            percentStudentDiscount: data.percentStudentDiscount,
            dataStudent: {
              isStudent: form.dataStudent.isStudent,
              studentId: data.student.id,

              name: data.student.nome,
              isForeign: data.student.estrangeiro,
              cpf: data.student.cpf,
              email: data.student.email,
              rgId: data.student.rg,
              bornDate:
                data.student.data_nascimento !== '0000-00-00'
                  ? String(data.student.data_nascimento)
                  : undefined,
              gender: data.student.sexo,
              pcd: data.student.pcd ? data.student.pcd : 10,
              colorRace: data.student?.raca,
              fatherName: data.student.pai,
              motherName: data.student.mae,
              nationality: data.student.nacionalidade,
              nativeness: data.student.naturalidade,

              degree: data.student.graduacao_curso,
              graduationYear: data.student.graduacao_ano,
              university: data.student.graduacao_instituicao,
              ceremony:
                data.student.graduacao_colacao !== '0000-00-00'
                  ? data.student.graduacao_colacao
                  : undefined,
              highSchoolGraduation: data.student.ano_conclusao_ensino_medio,

              cep: data.student.endereco.cep,
              address: data.student.endereco.logradouro,
              addressNumber: data.student.endereco.numero,
              addressComplement: data.student.endereco.complemento,
              neighborhood: data.student.endereco.bairro,
              city: data.student.endereco.cidade,
              uf: data.student.endereco.estado,
              municipalCode: data.student.endereco.codigo_municipio,
              homePhone: getPhone(data.student.telefone, 'homePhone'),
              cellPhone: getPhone(data.student.telefone, 'cellPhone'),
              businessPhone: getPhone(data.student.telefone, 'businessPhone'),

              companyName: data.student.empresa_contribuinte_nome,
              cnpj: data.student.empresa_contribuinte_cnpj,
              knowled: data.studentMedia.midia,
              knowledName: data.studentMedia.indicacao,
              knowledPhone: data.studentMedia.telefone,
            },
            courses: {
              title: ['Tipo', 'Curso'],
              content: data.courses?.map((e) => {
                const returnContentData: ICourseData = {
                  id: e.id,
                  type: { item: e.course_type },
                  course: { item: e.name },
                };
                return returnContentData;
              }),
            },
            previousSaledata: data.sales?.map((sale) => ({
              id: sale.id,
              agent: sale.agent,
              date: sale.date,
              amount: sale.amount,
              items: sale.items.map((item) => ({
                item: item.item,
                description: item.description,
                quantity: item.quantity,
                unitaryValue: item.unitary_value,
                discount: item.discount,
                totalItemValue: item.total_item_value,
                status: item.status,
              })),
            })),
          };
          setForm(newForm);
          setVerifyFields(newForm);
        }
      };

      setIsLoading(true);
      Promise.all([fetchNegotiationEdit(), fetchDataStudent()]).then(() => {
        setIsLoading(false);
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  }, [isOpen]);

  const handleClean = () => {
    setForm(defaultFormNegotiation);
    setNegotiation(defaultNegotiation);
    setItems([]);
    setSend(false);
    setCoupon(couponDefault);
    setPayment(defaultPayment);
    setIsOpen(false);
  };

  /** Estrutura para a as vendas anteriores */
  const previousSaledata = {
    title: ['Data da Venda', 'Vendedor', 'Valor Total', ''],
    content: form?.previousSaledata?.map((sale) => ({
      'Data da Venda': { item: sale.date },
      Vendedor: { item: sale.agent },
      'Valor Total': { item: sale.amount },
      '': {
        item: sale.items && (
          <ButtonOpenTable
            data={{
              title: [
                'Item',
                'Descrição',
                'Valor Unitário',
                'Desconto',
                'Total',
                'Status',
              ],
              content: sale.items?.map((item) => ({
                Item: { item: item.item },
                Descrição: { item: item.description },
                'Valor Unitário': { item: item.unitaryValue },
                Desconto: { item: item.discount },
                Total: { item: item.totalItemValue },
                Status: { item: item.status },
              })),
            }}
          />
        ),
      },
    })),
  };

  const sale = {
    form,
    send,
    items,
    setItems,
    negotiation,
    setNegotiation,
    valueTotal,
    setValueTotal,
    payment,
    setPayment,
    defaultPayment,
    discount,
    setDiscount,
    maxStudentValue,
    maxPaymentTypeValue,
    maxComboValue,
    idCardDiscount,
    setIdCardDiscount,
    cardDiscount,
    coupon,
    setCoupon,
    isEditSale,
    setIsEditSale,
    paymentTypeValue,
  };

  /** Estrutura de tabs para o Modal */
  const tabData = [
    {
      title: 'Edição da Venda',
      children: <TabSale data={sale} />,
    },
    {
      title: 'Dados',
      children: (
        <TabDataStudent
          form={form}
          setForm={setForm}
          send={send}
          verifyFields={verifyFields}
        />
      ),
    },
    {
      title: 'Vendas Anteriores',
      children: <TabPreviousSale data={previousSaledata} />,
    },
    {
      title: 'Lista de Cursos do Aluno',
      children: (
        <TabListCourses
          data={form?.courses}
          exception={['id', 'courseTypeId']}
        />
      ),
    },
  ];
  return (
    <BasicModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleOnClose={handleClean}
    >
      <HeaderModal
        setOpen={setIsOpen}
        title="Editar Venda"
        subtitle
        handleClose={handleClean}
      />
      <BasicTab data={tabData} />
      <FooterModal cancelClick={() => handleClean()} saveClick={handleSave} />
    </BasicModal>
  );
}
