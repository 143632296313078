import { defaultReturn } from '../../../util/newStudent';
import * as api from '../../api/newStudent/apiStudent.service';

interface Course {
  id: number;
  courseName: string;
  workload: number;
  accessCourse: boolean;
  urlExternal: string;
  expirationDate: string;
  courseId: number;
}

export interface ICourses {
  type: string;
  courses: Course[];
}

export interface ISubjectContent {
  contentId: number;
  contentName: string;
  contentType: number;
}

export interface Subject {
  disciplineId: number;
  disciplineName: string;
  workload: string;
  content: ISubjectContent[];
  examInProgress: boolean;
}

export interface ISubjects {
  moduleName: string;
  disciplines: Subject[];
}

export interface IDataContentSubject {
  title: string;
  url?: string;
  resourceId?: string;
  customized?: string;
}
export interface IContentSubject {
  video?: IDataContentSubject[];
  archive?: IDataContentSubject[];
  audiobook?: IDataContentSubject[];
  customized?: IDataContentSubject[];
  tcc?: IDataContentSubject[];
  internship?: IDataContentSubject[];
  examInProgress?: boolean;
}

interface IExamDescription {
  id: number;
  title: string;
  attempt: number;
  examType: number;
  response: boolean;
}
export interface IListOfExams {
  exams: IExamDescription[];
  generateDescriptiveExam?: string;
  generateClosedExam?: string;
}

interface IAlternative {
  alternativeId: number;
  alternative: string;
  text: string;
  response: boolean;
}

export interface IQuestion {
  questionId: number;
  question: string;
  url?: string;
  alternatives: IAlternative[];
  answer?: string;
  number: number;
  response: string;
  teacherObservation: string;
  result: boolean;
  date: string;
}

export interface IExam {
  examId: number;
  observation: string;
  examType: number;
  examQuestions: IQuestion[];
  response?: boolean;
  title?: string;
}

export interface IAnswers {
  questionId: number;
  alternativeId?: number | null;
  alternativeText?: string | null;
}

export interface IAnswerQuestion {
  examType: number;
  answers: IAnswers[] | [];
}

export interface IHistoric {
  academicProjectHistoricId: number;
  date: string;
  observation: string;
  status: string;
}

export interface IShowInternship {
  academicProjectId: number | string;
  date: string;
  canCreateHistoric?: boolean;
  subject: string;
  status: string;
  historic: IHistoric[];
}

export interface IRulesToSendTcc {
  title: string;
  structure: { title: string; text: string }[];
}

export interface IPillarTcc {
  title: string;
  subtitle: string;
  structure: { title: string; value?: string | number; completed?: boolean }[];
  percent?: number;
  completed: boolean;
}

export interface IDataPillarTcc {
  completed: boolean;
  requirements: IRulesToSendTcc;
  pillars: IPillarTcc[];
}

export interface IRulesToSend {
  title: string;
  information: string | null;
  structure: {
    title: string;
    text: string[];
  }[];
}

export const myCoursesServiceNewStudent = {
  async show(): Promise<{
    data: ICourses[];
    message: string[];
    result: boolean;
  }> {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-courses/show',
        'GET',
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async getPpc(courseId: number) {
    const request = await api.authenticatedRequest(
      '/student/my-courses/ppc',
      'POST',
      { courseId: courseId },
    );

    return defaultReturn(request);
  },

  async getDisciplines(id: number): Promise<{
    data: ISubjects[];
    message: string[];
    result: boolean;
  }> {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-courses/disciplines',
        'POST',
        { id: id },
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async getContent(
    id: number | string,
    disciplineId: number | string,
    contentId: number | string,
  ): Promise<{
    data: IContentSubject;
    message: string[];
    result: boolean;
  }> {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-courses/content',
        'POST',
        { id, disciplineId, contentId },
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async listExams(
    id: number,
    disciplineId: number,
    contentId: number,
  ): Promise<{
    data: IListOfExams;
    message: string[];
    result: boolean;
  }> {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-courses/exam/list-exams',
        'POST',
        {
          id,
          disciplineId,
          contentId,
        },
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async getExam(
    id: number,
    disciplineId: number,
    contentId: number,
    examId?: number,
  ): Promise<{
    data: IExam;
    message: string[];
    result: boolean;
  }> {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-courses/exam/get-exam',
        'POST',
        {
          id,
          disciplineId,
          contentId,
          examId,
        },
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async getAnswerSheet(examId?: number): Promise<{
    data: IExam;
    message: string[];
    result: boolean;
  }> {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-courses/exam/answers-list',
        'POST',
        {
          examId,
        },
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async answerQuestion(data: IAnswerQuestion): Promise<{
    data: boolean;
    message: string[];
    result: boolean;
  }> {
    const request = await api.authenticatedRequest(
      '/student/my-courses/exam/answer-question',
      'POST',
      data,
    );
    return request;
  },

  async finishExam(
    examId?: number | string,
    examType?: number | string,
  ): Promise<{
    data: boolean;
    message: string[];
    result: boolean;
  }> {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-courses/exam/answer-exam',
        'POST',
        {
          examId,
          examType,
        },
      );

      return defaultReturn(request);
    } catch (error) {
      throw new Error();
    }
  },

  async showAcademicProject(body: {
    id: string | number;
    disciplineId: string | number;
    contentId: string | number;
    resourceId: string | number;
  }): Promise<{
    data: {
      dismissalTcc?: boolean;
      finalCorrectionTcc?: boolean;
      orientationTcc?: boolean;
      paymentType?: boolean;
      tccObservationDismissal?: string[];
      overview?: string[];
      project?: IShowInternship[];
    };
    message: string[];
    result: boolean;
  }> {
    const request = await api.authenticatedRequest(
      '/student/my-courses/academic-projects/resource',
      'POST',
      body,
    );

    return request;
  },

  async saveNewHistoricInternship(data: FormData): Promise<{
    data: object | boolean;
    message: string[];
    result: boolean;
  }> {
    const request = await api.authenticatedRequest(
      '/student/my-courses/academic-projects/new-register',
      'POST',
      data,
      'file',
    );

    return defaultReturn(request);
  },

  async tccPillar(body: {
    id: number;
    disciplineId: number;
    contentId: number;
    resourceId: number;
  }): Promise<{
    data: IDataPillarTcc;
    message: string[];
    result: boolean;
  }> {
    const request = await api.authenticatedRequest(
      '/student/my-courses/academic-projects/pillar-resource',
      'POST',
      body,
    );
    return request;
  },

  async resourceRules({ resourceId, type }): Promise<{
    data: IRulesToSend;
    message: string[];
    result: boolean;
  }> {
    const request = await api.authenticatedRequest(
      '/student/my-courses/academic-projects/resource-rules',
      'POST',
      { resourceId, type },
    );

    return defaultReturn(request);
  },

  async resourceHistoricAttachment({
    resourceId,
    academicProjectId,
    academicProjectHistoricId,
  }): Promise<{
    data: { name: string; url: string }[];
    result: boolean;
    message: string[];
  }> {
    const request = await api.authenticatedRequest(
      '/student/my-courses/academic-projects/resource-historic',
      'POST',
      { resourceId, academicProjectId, academicProjectHistoricId },
    );

    return defaultReturn(request);
  },
};
