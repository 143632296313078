import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  padding-bottom: 4rem;
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
`;

export const H5 = styled.h5`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const H6 = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const Key = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};

  width: 10rem;
`;

export const KeyValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
`;

export const InputFile = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors?.primary};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors?.backgroundColor};

  cursor: pointer;
  margin-top: 0.5rem;

  max-width: 15rem;
  width: 100%;
  height: 3rem;

  input[type='file'] {
    display: none;
  }
`;

export const SelectArchive = styled.div`
  text-align: center;
`;

export const DivArchive = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
`;

export const BtnSuccess = styled.div`
  color: ${({ theme }) => theme.colors?.success};
  cursor: pointer;
  width: 2rem;
`;

export const BtnDanger = styled.div`
  color: ${({ theme }) => theme.colors?.danger};
  cursor: pointer;
  width: 2rem;
`;

export const ButtonSave = styled.div`
  color: ${({ theme }) => theme.colors?.success};
  cursor: pointer;
`;

export const Div = styled.div`
  display: flex;
  gap: 1rem;
`;
