import React, { useEffect, useRef } from 'react';

interface IChatFloatingProps {
  student: boolean;
}

const ChatFloating: React.FC<IChatFloatingProps> = ({ student }) => {
  const scriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    if (scriptRef.current) return;

    const scriptId = 'external-whatsapp-chat-script';

    if (!document.getElementById(scriptId)) {
      const script = createScriptElement(scriptId, student);
      document.body.appendChild(script);
      scriptRef.current = script;
    } else {
      console.log('Script já está carregado.');
    }

    return () => cleanUpScript(scriptRef.current);
  }, [student]);

  return null;
};

function createScriptElement(scriptId: string, student: boolean): HTMLScriptElement {
  const script = document.createElement('script');
  script.id = scriptId;
  script.src = student
    ? 'https://famart.edu.br/chat-whatsapp/student.js?v=1731265300'
    : 'https://famart.edu.br/chat-whatsapp/index.js?v=1731265300';
  script.type = 'text/javascript';
  script.async = true;
  script.onload = handleScriptLoad;
  script.onerror = handleScriptError;
  return script;
}

function handleScriptLoad(): void {
  if (typeof window !== 'undefined') {
    document.dispatchEvent(new Event('DOMContentLoaded'));
  }
}

function handleScriptError(): void {
  console.error('Erro ao carregar o script.');
}

function cleanUpScript(script: HTMLScriptElement | null): void {
  if (script && script.parentNode) {
    script.parentNode.removeChild(script);
    console.log('Script removido do DOM.');
  }
}

export default ChatFloating;
