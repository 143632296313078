import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import * as S from '../../styles';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';
import { FaCheck } from 'react-icons/fa';
import FieldFormik from '../../../../../../../../../components/newStudent/FieldFormik';
import { IDocumentsDegree } from '../../../../../..';
import { defaultDataUniversity } from '../../../..';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../../../../../components/newStudent/ProviderNewStudent';
import { documentCertificate } from '../../../../../../../../../services/newStudent/documentStudent/document.service';
import { cleanCache } from '../../../../../../../../../util/common/appUtil';
import { useTheme } from 'styled-components';

interface Props {
  item: IDocumentsDegree;
  isOld?: boolean;
  setNewData?: Dispatch<SetStateAction<IDocumentsDegree[] | []>>;
  newData?: IDocumentsDegree[];
  setOldData?: Dispatch<SetStateAction<IDocumentsDegree[] | []>>;
  oldData?: IDocumentsDegree[];
  index: number;
  documentDegreeType: number;
  registrationId: number;
  universityDegreeId: number;
}
export default function DegreeInput({
  item,
  isOld,
  setNewData,
  newData,
  registrationId,
  universityDegreeId,
  documentDegreeType,
  setOldData,
  oldData,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [dataItem, setDataItem] = useState(item);

  const handlePickFile = (file: File) => {
    setDataItem((old) => ({ ...old, file: file, name: file.name }));
  };

  const theme = useTheme();

  useEffect(() => {
    setDataItem(item);
  }, [item]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const body = {
        id: String(dataItem.id),
        url: dataItem.url,
        file: dataItem.file,
        type: documentDegreeType,
        finalDate: dataItem.endDate,
        workload: +dataItem.workload,
        initialDate: dataItem.startDate,
        registrationId: registrationId!,
        universityDegreeId: universityDegreeId!,
      };
      const request = await documentCertificate.uploadCertificate({ body });
      if (request.result) {
        cleanCache('dataDocuments');
        let tmpData = dataItem;
        if (!isOld) {
          tmpData = {
            ...dataItem,
            id: request.data.id,
            url: request.data.url,
          };
        }
        const updateData = [...newData!, tmpData];
        setNewData && setNewData(updateData);
        setDataItem(defaultDataUniversity);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (typeof item.id !== 'number') {
      const tmpData =
        newData?.filter((oldItem) => oldItem.id !== item.id) ?? [];
      setNewData && setNewData([...tmpData, defaultDataUniversity]);
      setDataItem(defaultDataUniversity);
    } else {
      try {
        setIsLoading(true);
        const body = {
          id: String(item.id),
          type: documentDegreeType,
          registrationId: registrationId!,
          universityDegreeId: universityDegreeId!,
        };
        const request = await documentCertificate.uploadCertificate({ body });
        if (request.result) {
          cleanCache('dataDocuments');
          if (!isOld) {
            const tmpData = newData?.filter(
              (oldItem) => oldItem.id !== item.id,
            ) ?? [defaultDataUniversity];
            setNewData && setNewData([...tmpData]);
            setDataItem(defaultDataUniversity);
          } else {
            const tmpData =
              oldData?.filter((oldItem) => +oldItem.id !== +item.id) ?? [];

            setOldData && setOldData(tmpData);
          }
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <S.Row className={'item_' + item.id}>
      <S.Td textAlign="center">
        <FieldFormik
          name="startDate"
          align="center"
          onChange={({ target }) =>
            setDataItem((old) => ({ ...old, startDate: target.value }))
          }
          disabled={isOld && dataItem.checked}
          type="date"
          value={dataItem.startDate}
          widthSize="small"
        />
      </S.Td>
      <S.Td>
        <FieldFormik
          name="endDate"
          align="center"
          onChange={({ target }) =>
            setDataItem((old) => ({ ...old, endDate: target.value }))
          }
          disabled={isOld && dataItem.checked}
          type="date"
          value={dataItem.endDate}
          widthSize="small"
        />
      </S.Td>
      <S.Td>
        <FieldFormik
          name="workload"
          align="center"
          onChange={({ target }) =>
            setDataItem((old) => ({ ...old, workload: target.value }))
          }
          disabled={isOld && dataItem.checked}
          type="number"
          value={dataItem.workload}
          widthSize="xxsmall"
        />
      </S.Td>
      <S.Td>
        <S.NameDiv>
          {!dataItem.file && !isOld ? (
            <S.InputFile>
              <span> Escolher arquivo </span>
              <input
                type="file"
                className="input-file"
                onChange={({ target }) => handlePickFile(target.files![0])}
              />
            </S.InputFile>
          ) : (
            <S.Text>{dataItem.name}</S.Text>
          )}
          <S.ButtonsDiv>
            {!isOld ? (
              !!dataItem.file && (
                <>
                  {JSON.stringify(item) !== JSON.stringify(dataItem) &&
                    dataItem.name && (
                      <S.ButtonSave onClick={() => handleSave()}>
                        <UploadFileIcon fontSize="large" />
                      </S.ButtonSave>
                    )}
                  <S.ButtonDelete onClick={() => handleDelete()}>
                    <DeleteForeverIcon fontSize="large" />
                  </S.ButtonDelete>
                </>
              )
            ) : !dataItem.checked ? (
              <>
                <S.ButtonDownload
                  href={dataItem.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <DownloadIcon fontSize="large" />
                </S.ButtonDownload>
                {JSON.stringify(item) !== JSON.stringify(dataItem) && (
                  <S.ButtonSave onClick={() => handleSave()}>
                    <UploadFileIcon fontSize="large" />
                  </S.ButtonSave>
                )}
                <S.ButtonDelete onClick={() => handleDelete()}>
                  <DeleteForeverIcon fontSize="large" />
                </S.ButtonDelete>
              </>
            ) : (
              <FaCheck color={theme?.colors?.success} />
            )}
          </S.ButtonsDiv>
        </S.NameDiv>
      </S.Td>
    </S.Row>
  );
}
