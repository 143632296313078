import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import * as S from './styles';
import FormField from '../../../FormField';
import { IFieldForm } from '../../../FormField';
import { authenticatedRequest } from '../../../../../services/api/agent/api.agent.service';
import { errorToast } from '../../../../common/Toast';
import { IFormDataStudent } from '../../../../../services/agent/student/interface';

interface Props {
  form: IFormDataStudent;
  setForm: Dispatch<SetStateAction<IFormDataStudent>>;
  verifyFields: IFormDataStudent;
  send?: boolean;
  cpfVerify?: number | string | undefined;
  setCpfVerify?: Dispatch<SetStateAction<number | string | undefined>>;
}
export default function TabDataStudent({
  form,
  setForm,
  verifyFields,
  send,
  cpfVerify,
  setCpfVerify,
}: Props) {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const personalStructure: IFieldForm[] = [
    {
      title: 'Nome',
      placeholder: 'Nome',
      name: 'name',
      isInvalid: send && !form?.dataStudent.name,
      type: 'string',
      value: form?.dataStudent.name,
      disabled: form?.dataStudent.isStudent && !!verifyFields.dataStudent.name,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, name: e.target.value },
        }),
      lg: 6,
    },
    {
      title: 'Aluno Estrangeiro?',
      placeholder: undefined,
      name: 'isForeign',
      type: 'checkbox',
      checked: form?.dataStudent.isForeign,
      disabled:
        form?.dataStudent.isStudent && !verifyFields.dataStudent.isForeign,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, isForeign: e.target.checked },
        }),
      lg: 3,
    },
    {
      title: 'CPF',
      placeholder: 'CPF',
      name: 'cpf',
      isInvalid: send && !form?.dataStudent.cpf,
      type: 'string',
      value: form?.dataStudent.cpf,
      disabled: form?.dataStudent.isStudent && !!verifyFields.dataStudent.cpf,
      maxLength: 11,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, cpf: e.target.value },
        }),
      lg: 3,
    },
    {
      title: 'Email',
      placeholder: 'Email',
      name: 'email',
      isInvalid: send && !form?.dataStudent.email,
      type: 'string',
      value: form?.dataStudent.email,
      disabled: form?.dataStudent.isStudent && !!verifyFields.dataStudent.email,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, email: e.target.value },
        }),
      lg: 6,
    },
    {
      title: 'RG/Identidade',
      placeholder: 'RG/Identidade',
      name: 'rgId',
      isInvalid: send && !form?.dataStudent.rgId,
      type: 'string',
      value: form?.dataStudent.rgId,
      disabled: form?.dataStudent.isStudent && !!verifyFields.dataStudent.rgId,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, rgId: e.target.value },
        }),
      lg: 3,
    },
    {
      title: 'Data de Nasc.',
      placeholder: 'Data de Nascimento',
      name: 'bornDate',
      isInvalid: send && !form?.dataStudent.bornDate,
      type: 'date',
      value: form?.dataStudent.bornDate,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.bornDate,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, bornDate: e.target.value },
        }),
      lg: 3,
    },
    {
      title: 'Sexo',
      placeholder: 'Sexo',
      name: 'gender',
      isInvalid: send && !form?.dataStudent.gender,
      type: 'select',
      value: form?.dataStudent.gender,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.gender,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, gender: e.target.value },
        }),
      lg: 3,
      options: [
        { title: '[ SELECIONE ]', value: undefined },
        { title: 'Masculino', value: '1' },
        { title: 'Feminino', value: '2' },
      ],
    },
    {
      title: 'Pessoa com Deficiência?',
      placeholder: 'Pessoa com Deficiência?',
      name: 'pcd',
      type: 'select',
      value: form?.dataStudent.pcd,
      disabled: form?.dataStudent.isStudent,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, pcd: e.target.value },
        }),
      lg: 3,
      options: [
        { title: 'Não', value: 10 },
        { title: 'Auditiva', value: 1 },
        { title: 'Visual', value: 2 },
        { title: 'Física', value: 3 },
        { title: 'Intelectual', value: 4 },
        { title: 'Transtorno do espectro autista', value: 5 },
      ],
    },
    {
      title: 'Se declara na cor/raça',
      placeholder: 'Se declara na cor/raça',
      name: 'colorRace',
      type: 'select',
      value: form?.dataStudent.colorRace,
      disabled:
        !!form?.dataStudent.colorRace && !!verifyFields.dataStudent.colorRace,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, colorRace: e.target.value },
        }),
      lg: 3,
      options: [
        { title: '[ Selecione ]', value: 0 },
        { title: 'Branca', value: 1 },
        { title: 'Preta', value: 2 },
        { title: 'Amarela', value: 3 },
        { title: 'Parda', value: 4 },
        { title: 'Indigena', value: 5 },
        { title: 'Outra', value: 6 },
      ],
    },
    {
      title: 'Nome do Pai',
      placeholder: 'Nome do Pai',
      name: 'fatherName',
      isInvalid: send && !form?.dataStudent.fatherName,
      type: 'string',
      value: form?.dataStudent.fatherName,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.fatherName,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, fatherName: e.target.value },
        }),
      lg: 12,
    },
    {
      title: 'Nome da Mãe',
      placeholder: 'Nome da Mãe',
      name: 'motherName',
      isInvalid: send && !form?.dataStudent.motherName,
      type: 'string',
      value: form?.dataStudent.motherName,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.motherName,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, motherName: e.target.value },
        }),
      lg: 12,
    },
    {
      title: 'Nacionalidade',
      placeholder: 'Nacionalidade',
      name: 'nationality',
      type: 'string',
      value: form?.dataStudent.nationality,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.nationality,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, nationality: e.target.value },
        }),
      lg: 6,
    },
    {
      title: 'Naturalidade',
      placeholder: 'Naturalidade',
      name: 'nativeness',
      type: 'string',
      value: form?.dataStudent.nativeness,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.nativeness,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, nativeness: e.target.value },
        }),
      lg: 6,
    },
  ];

  const courseStructure: IFieldForm[] = [
    {
      title: 'Curso de Graduação',
      placeholder: 'Curso de Graduação',
      name: 'degree',
      type: 'string',
      value: form?.dataStudent.degree,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.degree,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, degree: e.target.value },
        }),
      lg: 9,
    },
    {
      title: 'Ano Formação',
      placeholder: 'AAAA',
      name: 'graduationYear',
      isInvalid:
        send && !form?.dataStudent.graduationYear && !!form?.dataStudent.degree,
      type: 'number',
      maxLength: 4,
      value: form?.dataStudent.graduationYear,
      disabled:
        form?.dataStudent.isStudent &&
        !!verifyFields.dataStudent.graduationYear,
      handleChange: (e) => {
        setForm({
          ...form,
          dataStudent: {
            ...form?.dataStudent,
            graduationYear: e.target.value,
          },
        });
      },
      lg: 3,
    },
    {
      title: 'Instituição Graduação',
      placeholder: 'Instituição Graduação',
      name: 'university',
      type: 'string',
      value: form?.dataStudent.university,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.university,
      handleChange: (e) => {
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, university: e.target.value },
        });
      },
      lg: 6,
    },
    {
      title: 'Colação de Grau',
      placeholder: 'Colação de Grau',
      name: 'ceremony',
      isInvalid: send && !form?.dataStudent.ceremony,
      type: 'date',
      value: form?.dataStudent.ceremony,
      disabled:
        form?.dataStudent.isStudent &&
        !!verifyFields.dataStudent.ceremony &&
        !!form?.dataStudent.degree,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, ceremony: e.target.value },
        }),
      lg: 3,
    },
    {
      title: 'Ano Conc. Ensino Médio',
      placeholder: 'AAAA',
      name: 'highSchoolGraduation',
      type: 'number',
      maxLength: 4,
      value: form?.dataStudent.highSchoolGraduation,
      disabled:
        form?.dataStudent.isStudent &&
        !!verifyFields.dataStudent.highSchoolGraduation,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: {
            ...form?.dataStudent,
            highSchoolGraduation: e.target.value,
          },
        }),
      lg: 3,
    },
  ];

  const addressStructure: IFieldForm[] = [
    {
      title: 'CEP',
      placeholder: 'CEP',
      name: 'cep',
      isInvalid: send && !form?.dataStudent.cep,
      type: 'string',
      value: form?.dataStudent.cep,
      disabled: form?.dataStudent.isStudent && !!verifyFields.dataStudent.cep,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, cep: e.target.value },
        }),

      lg: 3,
    },
    {
      title: 'Logradouro',
      placeholder: 'Logradouro',
      name: 'address',
      isInvalid: send && !form?.dataStudent.address,
      type: 'string',
      value: form?.dataStudent.address,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.address,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, address: e.target.value },
        }),
      lg: 9,
    },
    {
      title: 'Número',
      placeholder: 'Número',
      name: 'addressNumber',
      isInvalid: send && !form?.dataStudent.addressNumber,
      type: 'string',
      value: form?.dataStudent.addressNumber,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.addressNumber,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, addressNumber: e.target.value },
        }),
      lg: 3,
    },
    {
      title: 'Complemento',
      placeholder: 'Complemento',
      name: 'addressComplement',
      type: 'string',
      value: form?.dataStudent.addressComplement,
      disabled:
        form?.dataStudent.isStudent &&
        !!verifyFields.dataStudent.addressComplement,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: {
            ...form?.dataStudent,
            addressComplement: e.target.value,
          },
        }),
      lg: 3,
    },
    {
      title: 'Bairro',
      placeholder: 'Bairro',
      name: 'neighborhood',
      isInvalid: send && !form?.dataStudent.neighborhood,
      type: 'string',
      value: form?.dataStudent.neighborhood,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.neighborhood,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, neighborhood: e.target.value },
        }),
      lg: 6,
    },
    {
      title: 'Cidade',
      placeholder: 'Cidade',
      name: 'city',
      isInvalid: send && !form?.dataStudent.city,
      type: 'string',
      value: form?.dataStudent.city,
      disabled: form?.dataStudent.isStudent && !!verifyFields.dataStudent.city,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, city: e.target.value },
        }),
      lg: 6,
    },
    {
      title: 'UF',
      placeholder: 'UF',
      name: 'uf',
      isInvalid: send && !form?.dataStudent.uf,
      type: 'string',
      maxLength: 2,
      value: form?.dataStudent.uf,
      disabled: form?.dataStudent.isStudent && !!verifyFields.dataStudent.uf,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, uf: e.target.value },
        }),
      lg: 2,
    },
    {
      title: 'Cód. de Município',
      placeholder: 'Código de Município',
      name: 'municipalCode',
      type: 'number',
      value: form?.dataStudent.municipalCode,
      disabled: true,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, municipalCode: e.target.value },
        }),
      lg: 2,
    },
    {
      title: 'Tel. Residencial',
      placeholder: 'Telefone Residencial',
      name: 'homePhone',
      type: 'string',
      value: form?.dataStudent.homePhone,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.homePhone,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, homePhone: e.target.value },
        }),
      lg: 4,
    },
    {
      title: 'Tel. Celular',
      placeholder: 'Telefone Celular',
      name: 'cellPhone',
      isInvalid: send && !form?.dataStudent.cellPhone,
      type: 'string',
      value: form?.dataStudent.cellPhone,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.cellPhone,
      handleChange: (e) => {
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, cellPhone: e.target.value },
        });
      },
      lg: 4,
    },
    {
      title: 'Tel. Comercial',
      placeholder: 'Telefone Comercial',
      name: 'businessPhone',
      isInvalid: send && !form?.dataStudent.businessPhone,
      type: 'string',
      value: form?.dataStudent.businessPhone,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.businessPhone,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, businessPhone: e.target.value },
        }),
      lg: 4,
    },
  ];

  const businessStructure: IFieldForm[] = [
    {
      title: 'Nome Empresa Contribuinte',
      placeholder: 'Empresa',
      name: 'companyName',
      type: 'string',
      value: form?.dataStudent.companyName,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.companyName,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, companyName: e.target.value },
        }),
      lg: 9,
    },
    {
      title: 'CNPJ Empresa Contribuinte',
      placeholder: 'CNPJ',
      name: 'cnpj',
      type: 'number',
      value: form?.dataStudent.cnpj,
      disabled: form?.dataStudent.isStudent && !!verifyFields.dataStudent.cnpj,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, cnpj: e.target.value },
        }),
      lg: 3,
    },
  ];

  const informationStructure: IFieldForm[] = [
    {
      title: 'Fonte',
      placeholder: '',
      name: 'knowled',
      type: 'select',
      value: form?.dataStudent.knowled,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.knowled,
      handleChange: (e) => {
        if (e.target.value === '13' || e.target.value === '14') {
          setForm({
            ...form,
            dataStudent: { ...form?.dataStudent, knowled: e.target.value },
          });
        } else {
          setForm({
            ...form,
            dataStudent: {
              ...form?.dataStudent,
              knowled: e.target.value,
              knowledName: '',
              knowledPhone: '',
            },
          });
        }
      },
      lg: 4,
      options: [
        { title: '[ Selecione ]', value: undefined },
        { title: 'E-mail Marketing', value: '7' },
        { title: 'Indicação de amigos', value: '13' },
        { title: 'Indicação de parceiro', value: '14' },
        { title: 'Lead Line', value: '22' },
        { title: 'Blip', value: '23' },
        { title: 'Cadastro Site', value: '24' },
      ],
    },
  ];
  const indicationStructure: IFieldForm[] = [
    {
      title: 'Nome',
      placeholder: 'Nome',
      name: 'knowledName',
      isInvalid: send && !form?.dataStudent.knowledName,
      type: 'string',
      value: form?.dataStudent.knowledName,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.knowledName,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, knowledName: e.target.value },
        }),
      lg: 4,
    },
    {
      title: 'Telefone ',
      placeholder: 'Celular',
      name: 'knowledPhone',
      isInvalid: send && !form?.dataStudent.knowledPhone,
      type: 'string',
      value: form?.dataStudent.knowledPhone,
      disabled:
        form?.dataStudent.isStudent && !!verifyFields.dataStudent.knowledPhone,
      handleChange: (e) =>
        setForm({
          ...form,
          dataStudent: { ...form?.dataStudent, knowledPhone: e.target.value },
        }),
      lg: 4,
    },
  ];

  useEffect(() => {
    setCpfVerify && setCpfVerify(form?.dataStudent.cep);

    if (cpfVerify === form?.dataStudent.cep) {
      return;
    }
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      const fetchData = async () => {
        if (
          form?.dataStudent.cep === undefined ||
          form?.dataStudent.cep === null ||
          (form?.dataStudent.isStudent && verifyFields.dataStudent.cep)
        ) {
          return;
        }
        const cepRegex = form?.dataStudent.cep!.replace(/[^0-9]/g, '');
        if (cepRegex.length === 8) {
          const { data, message, result } = await authenticatedRequest(
            '/helper/consult-adress',
            'POST',
            {
              cep: cepRegex,
            },
          );

          if (result) {
            return setForm({
              ...form,
              dataStudent: {
                ...form?.dataStudent,
                address: data.logradouro,
                neighborhood: data.bairro,
                city: data.cidade,
                uf: data.uf,
                municipalCode: data.ibge,
              },
            });
          } else {
            message?.length > 0 &&
              message.forEach((text) => {
                errorToast(text);
              });
          }
        }
      };

      fetchData();
    }, 500);

    setTimer(newTimer);
    return () => {
      if (newTimer) {
        clearTimeout(newTimer);
      }
    };
  }, [form?.dataStudent.cep]);

  return (
    <S.Container>
      <S.Contents>
        <S.Fieldset>
          {personalStructure.map((item, index) => {
            return <FormField item={item} key={index} />;
          })}
        </S.Fieldset>
      </S.Contents>
      <S.Contents>
        <S.Fieldset>
          {courseStructure.map((item, index) => {
            return <FormField item={item} key={index} />;
          })}
        </S.Fieldset>
      </S.Contents>
      <S.Contents>
        <S.Fieldset>
          {addressStructure.map((item, index) => {
            return <FormField item={item} key={index} />;
          })}
        </S.Fieldset>
      </S.Contents>
      <S.Contents>
        <S.Fieldset>
          {businessStructure.map((item, index) => {
            return <FormField item={item} key={index} />;
          })}
        </S.Fieldset>
      </S.Contents>
      <S.Contents>
        <S.Fieldset>
          {informationStructure.map((item, index) => {
            return <FormField item={item} key={index} />;
          })}
          {(String(form?.dataStudent.knowled) == '13' ||
            String(form?.dataStudent.knowled) == '14') &&
            indicationStructure.map((item, index) => {
              return <FormField item={item} key={index} />;
            })}
        </S.Fieldset>
      </S.Contents>
    </S.Container>
  );
}
