import React, { useContext, useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import LockIcon from '@mui/icons-material/Lock';

import * as S from './styles';
import FieldFormik from '../../../../../components/newStudent/FieldFormik';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import { authNewStudentService } from '../../../../../services/auth';
import { IDataUpdate } from '../../../../../services/auth/newStudent/auth.newStudent.service';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import { useNavigate } from 'react-router-dom';

interface Props {
  token: string;
}
export default function NewPassword({ token }: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;
  const [successSend, setSuccessSend] = useState(false);

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    newPassword: Yup.string().required('Digite a senha'),
    confirmPassword: Yup.string()
      .required('Digite a confirmação de senha')
      .oneOf([Yup.ref('newPassword')], 'Senhas não correspondem'),
  });

  const initialValues = {
    newPassword: '',
    passwordConfirmation: '',
    token: token,
  };

  const handleSubmit = (values: IDataUpdate) => {
    setIsLoading(true);

    const fetch = async () => {
      try {
        const request = await authNewStudentService.updatePassword(values);
        if (request.result) {
          setSuccessSend(true);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  };

  return (
    <S.Container>
      {successSend ? (
        <S.ContainerSuccess>
          <S.Title>Senha Redefinida com sucesso!</S.Title>
          <S.SubTitle>Faça o login com sua nova senha.</S.SubTitle>
          <S.ButtomContainer>
            <ButtonSimple
              type="submit"
              color="primary"
              font="h4"
              heightSize="large"
              onClick={() => navigate('/new/aluno')}
            >
              Faça o Login
            </ButtonSimple>
          </S.ButtomContainer>
        </S.ContainerSuccess>
      ) : (
        <>
          <S.ContainerTitle>
            <S.Title>Redefinir Senha</S.Title>
            <S.SubTitle>Criar sua nova senha de acesso</S.SubTitle>
          </S.ContainerTitle>
          <Formik
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={initialValues}
          >
            {({ handleChange, values }) => (
              <S.StyledForm>
                <S.FormFields>
                  <FieldFormik
                    name="newPassword"
                    type="password"
                    placeholder="Nova senha"
                    widthSize="fullWidth"
                    heightSize="large"
                    onChange={handleChange}
                    leftIcon={<LockIcon />}
                    error
                  />
                  <FieldFormik
                    name="passwordConfirmation"
                    type="password"
                    placeholder="Confirmar senha"
                    widthSize="fullWidth"
                    heightSize="large"
                    onChange={handleChange}
                    leftIcon={<LockIcon />}
                    error
                  />
                  <S.Forgot>
                    <S.A onClick={() => navigate('/new/aluno')}>
                      Voltar para o login!
                    </S.A>
                  </S.Forgot>
                </S.FormFields>
                <S.P>
                  A senha deve conter de 8 à 20 caracteres com uma combinação de
                  letras maiúsculas e minúsculas, números e símbolos. (A senha
                  não pode conter ponto, espaço e aspas).
                </S.P>
                <ButtonSimple
                  type="submit"
                  color="primary"
                  font="h4"
                  heightSize="large"
                  onClick={() => handleSubmit(values)}
                >
                  Salvar
                </ButtonSimple>
              </S.StyledForm>
            )}
          </Formik>
        </>
      )}
    </S.Container>
  );
}
