import React, { useContext, useState } from 'react';

import * as S from './styles';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonIcon, ButtonSimple } from '../../../../../Buttons';
import { SaleModalItem } from './components';
import { errorToast } from '../../../../../../common/Toast';
import {
  IAgentContextProps,
  ProviderAgentContext,
} from '../../../../../ProviderAgent';
import { BasicTable } from '../../../../../../common/Table';
import {
  typeServiceExam,
  saleItemTypeCourse,
  saleItemTypeService,
} from '../../../../../../../variables';
import { toUpperCase } from '../../../../../../../util/common/appUtil';
import { saleNegotiationService } from '../../../../../../../services/agent';
import { IItem } from '../../../../../../../services/agent/student/interface';

interface IEditItem {
  index?: number;
  edit: IItem;
}

export const defaultItemSale: IItem = {
  item: {
    value: '',
    title: '',
    options: [
      { title: '[ ITEM ]', value: 0 },
      { title: 'CURSO', value: Number(saleItemTypeCourse) },
      { title: 'SERVIÇO', value: Number(saleItemTypeService) },
    ],
  },
  type: {
    value: '',
    title: '',
    options: [{ title: '[ SELECIONE ]', value: 0 }],
  },
  description: {
    value: '',
    title: '',
    observation: undefined,
    options: [
      {
        title: '[ SELECIONE ]',
        value: 0,
        maxDiscount: 0,
        courseValue: 0,
        observation: undefined,
      },
    ],
  },
  value: { value: 0 },
  discount: { value: 0 },
};

export default function SaleItem({
  items,
  setItems,
  dontSearch,
  setDontSearch,
  isEditSale,
  setNegotiation,
  negotiation,
  form,
}) {
  const context = useContext(ProviderAgentContext);
  const { setIsLoading } = context as IAgentContextProps;

  const [openNewItemModal, setOpenNewItemModal] = useState(false);
  const [editItem, setEditItem] = useState<IEditItem>({
    index: undefined,
    edit: defaultItemSale,
  });

  /** Deleta o item no array */
  const handleDeleteItem = (index) => {
    dontSearch.find((e, i) => {
      if (
        +e.item === +items[index].item.value &&
        +e.productId === +items[index].description.value
      ) {
        dontSearch.splice(i, 1);
        return true;
      }
    });

    const updatedItems = [...items];
    /**
     * Verifica se é um vestibular para a exclusão
     * se sim, coloca a isenção do vestibular como false
     */
    if (
      +updatedItems[index].type.value === +typeServiceExam! &&
      +updatedItems[index].item.value === +saleItemTypeService! &&
      +updatedItems[index].description.value === 3
    ) {
      setNegotiation((old) => ({
        ...old,
        entranceExamination: 0,
      }));
    }
    updatedItems.splice(index, 1);

    setItems(updatedItems);
  };

  /** Adiciona o item no array */
  const handleAddItem = (newItem) => {
    setDontSearch((old) => [
      ...old,
      { item: newItem.item.value, productId: +newItem.description.value },
    ]);
    setItems((oldItems) => [...oldItems, newItem]);
  };

  /** Carrega o item para ser editado */
  const handleFetchItem = async (index) => {
    setIsLoading(true);

    if (isEditSale) {
      const newItemEdit = { ...defaultItemSale, ...items[index] };
      const descriptionPromise = saleNegotiationService
        .consultByType(
          items[index].item.value,
          items[index].type.value,
          editItem.edit ? dontSearch : null,
          form?.dataStudent.cpf
            ? form?.dataStudent.cpf
            : form?.dataStudent.email,
        )
        .then(({ data, result, message }) => {
          if (!result) {
            message.forEach((element: string) => {
              errorToast(element);
            });
          }
          if (data) {
            const itemMap = data.map((e) => ({
              title: e.name,
              value: +e.id,
              maxDiscount: +e.discount,
              courseValue: +e.value,
            }));

            newItemEdit.description = {
              ...newItemEdit.description,
              options: [
                {
                  title: '[ SELECIONE ]',
                  value: undefined,
                  maxDiscount: undefined,
                  courseValue: undefined,
                },
                ...itemMap,
              ],
            };
            setItems((oldItems) => [
              ...oldItems.slice(0, index),
              {
                ...oldItems[index],
                description: {
                  ...newItemEdit.description,
                  options: [
                    {
                      title: '[ SELECIONE ]',
                      value: undefined,
                      maxDiscount: undefined,
                      courseValue: undefined,
                    },
                    ...itemMap,
                  ],
                },
              },

              ...oldItems.slice(index + 1),
            ]);
          } else {
            return null;
          }
        });

      const typePromise = await saleNegotiationService
        .consultType(items[index].item.value)
        .then(({ result, data, message }) => {
          if (!result) {
            message.forEach((element) => {
              errorToast(element);
            });
            return null;
          }

          const itemMap = data.map((e) => ({
            title: e.nome,
            value: e.id,
          }));

          newItemEdit.type = {
            ...newItemEdit.type,
            options: [{ title: '[ SELECIONE ]', value: undefined }, ...itemMap],
          };
          setItems((oldItems) => [
            ...oldItems.slice(0, index),
            {
              ...oldItems[index],
              type: {
                ...newItemEdit.type,
                options: [
                  { title: '[ SELECIONE ]', value: undefined },
                  ...itemMap,
                ],
              },
            },

            ...oldItems.slice(index + 1),
          ]);
        });

      const promises = [typePromise, descriptionPromise];

      return Promise.all(promises).then(() => {
        setEditItem({ edit: newItemEdit, index: index });
        setIsLoading(false);
        setOpenNewItemModal(true);
      });
    }

    setIsLoading(false);
    setEditItem({ edit: items[index], index: index });
    setOpenNewItemModal(true);
  };

  const data = {
    content: items.map((item, index) => ({
      item: {
        item: <S.TextItems>{toUpperCase(item?.item?.title)}</S.TextItems>,
        style: { width: '10rem', paddingTop: '0.5rem' },
      },
      type: {
        item: <S.TextItems>{toUpperCase(item?.type?.title)}</S.TextItems>,
        style: { width: '30rem', paddingTop: '0.5rem' },
      },
      description: {
        item: (
          <S.TextItems>{toUpperCase(item?.description?.title)}</S.TextItems>
        ),
        style: { paddingTop: '0.5rem' },
      },
      discount: {
        item: (
          <S.TextItems>
            {(item?.discount?.value !== undefined &&
            item?.value?.value !== undefined
              ? item?.value?.value - item?.discount?.value
              : item?.value?.value
            )?.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
            })}
          </S.TextItems>
        ),
        style: { width: '130px', paddingTop: '0.5rem' },
      },
      '': {
        item: (
          <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
            <ButtonIcon color="icon" onClick={() => handleDeleteItem(index)}>
              <DeleteIcon fontSize="large" color="error" />
            </ButtonIcon>

            <ButtonIcon color="icon" onClick={() => handleFetchItem(index)}>
              <EditIcon fontSize="large" color="primary" />
            </ButtonIcon>
          </div>
        ),
        style: { width: '6rem', padding: 0 },
      },
    })),
  };
  return (
    <S.Container>
      <S.ContainerHeader>
        <S.ContainerTitle>
          <S.Title>Itens</S.Title>
          <ButtonSimple
            icon={<EditIcon />}
            size="medium"
            color="blue"
            onClick={() => {
              setEditItem(() => ({
                index: undefined,
                edit: defaultItemSale,
              }));
              setOpenNewItemModal(true);
            }}
          >
            Adicionar
          </ButtonSimple>
        </S.ContainerTitle>
        <S.Table>
          <BasicTable
            titleNotFound="Nenhum item incluso ainda..."
            data={data}
            noBorder
          />
        </S.Table>
      </S.ContainerHeader>
      <SaleModalItem
        openNewItemModal={openNewItemModal}
        setOpenNewItemModal={setOpenNewItemModal}
        handleAddItem={handleAddItem}
        setNegotiation={setNegotiation}
        negotiation={negotiation}
        editItem={editItem}
        setEditItem={setEditItem}
        setItems={setItems}
        form={form}
        dontSearch={dontSearch}
      />
    </S.Container>
  );
}
