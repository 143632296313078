import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import * as S from './styles';
import BulletPoint from '../../../../../components/newStudent/BulletPoint';
import ButtonOpen from '../../../../../components/newStudent/Buttons/ButtonOpen';
import TableBasic, {
  ITableData,
} from '../../../../../components/newStudent/Table';
import { useTheme } from 'styled-components';
import { handleMaxWidth } from '../../../../../util/newStudent';
import TableCollapseTitle from '../TableCollapseTitle';
import {
  ICourses,
  IDisciplineGardesShow,
  myGradesServiceNewStudent,
} from '../../../../../services/newStudent/myGrades/myGrades.service';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';

export interface IGradeInterfaceMobile {
  title: string;
  grades: {
    discipline: string;
    grade: number | string;
    situation: number;
    status: string;
    color: 'secondary' | 'danger' | 'success';
  }[];
}
interface Props {
  item: ICourses;
  index: number;
  isOpen: number | undefined;
  setIsOpen: Dispatch<SetStateAction<number | undefined>>;
}
export default function CollapseGrades({ item, isOpen, setIsOpen }: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [data, setData] = useState<IDisciplineGardesShow[]>();

  const theme = useTheme();

  const handleClick = () => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const { result, data } = await myGradesServiceNewStudent.grades(
          item.id,
        );
        if (result) {
          setData(data);

          isOpen !== item.id ? setIsOpen(item.id) : setIsOpen(undefined);
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  };

  const statusEnum = {
    'Em Andamento': { color: 'secondary', status: 'Andamento' },
    'Em Recuperação': { color: 'danger', status: 'Recuperação' },
    Aprovado: { color: 'success', status: 'Aprovado' },
    Dispensado: { color: 'success', status: 'Dispensado' },
    Reprovado: { color: 'danger', status: 'Reprovado' },
  };

  const dataTable: ITableData[] =
    data?.map((grade) => ({
      title: [
        {
          item: <S.Title>{grade.moduleName.toUpperCase()}</S.Title>,
        },
        { item: <S.TitleSmall>Nota</S.TitleSmall>, align: 'center' },

        {
          item: <S.TitleSmall>Situação</S.TitleSmall>,
          align: 'center',
        },
      ],
      content: grade.disciplines.map((itemGrade) => ({
        discipline: {
          item: (
            <S.DivDiscipline>
              <S.Name>{itemGrade.disciplineName.toUpperCase()}</S.Name>
            </S.DivDiscipline>
          ),
        },
        grade: {
          item: <S.Grade>{itemGrade.grade}</S.Grade>,
          align: 'center',
        },
        situation: {
          align: 'center',
          item: (
            <S.H6
              style={{
                color: theme.colors?.[statusEnum[itemGrade.status].color],
              }}
            >
              {statusEnum[itemGrade.status].status}
            </S.H6>
          ),
        },
      })),
    })) ?? [];

  const dataTableMobile: IGradeInterfaceMobile[] =
    data?.map((grade: IDisciplineGardesShow) => ({
      title: `${grade.moduleName.toUpperCase()}`,
      grades: grade.disciplines.map((itemGrade) => ({
        discipline: itemGrade.disciplineName.toUpperCase(),
        grade: itemGrade.grade,
        situation: itemGrade.attempt,
        status: statusEnum[itemGrade.status].status,
        color: statusEnum[itemGrade.status].color,
      })),
    })) ?? [];

  return (
    <S.Container>
      <BulletPoint
        color="success"
        buttons={
          <ButtonOpen
            title="Ver Notas"
            titleClose="Fechar"
            open={isOpen === item.id}
            onClick={() => handleClick()}
          />
        }
        flexDirection={handleMaxWidth(theme.size?.medium) ? 'column' : 'row'}
      >
        {item.courseName}
      </BulletPoint>
      {isOpen === item.id && (
        <S.ContentFocus>
          {handleMaxWidth(theme.size?.medium)
            ? dataTableMobile.map((grade, index) => (
                <TableCollapseTitle key={index} data={grade} />
              ))
            : dataTable.map((grade, index) => (
                <S.Table key={index}>
                  <TableBasic data={grade} />
                </S.Table>
              ))}
        </S.ContentFocus>
      )}
    </S.Container>
  );
}
