import React, { useContext, useEffect, useRef, useState } from 'react';

import * as S from './styles';

import TableBasic, {
  ITableData,
} from '../../../../../components/newStudent/Table';

import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import ClassModal from '../../../../../components/newStudent/Modal/ClassModal';
import {
  handleMaxWidth,
  toastErrorMessage,
} from '../../../../../util/newStudent';
import { useTheme } from 'styled-components';
import Divider from '../../../../../components/common/Divider';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import { myCoursesServiceNewStudent } from '../../../../../services/newStudent';
import {
  ISubjectContent,
  ISubjects,
} from '../../../../../services/newStudent/myCourses/myCourses.service';

export interface IClassInfo {
  id: number | string;
  content?: ISubjectContent[] | [];
  title?: string;
  subtitle?: string;
  courseId: number;
  examInProgress: boolean;
}

interface Props {
  id: number;
}
export default function Subjects({ id }: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [target, setTarget] = useState<number>();
  const [isClassOpen, setIsClassOpen] = useState<boolean>(false);
  const [classInfo, setClassInfo] = useState<IClassInfo>({
    id: 0,
    examInProgress: false,
    courseId: 0,
  });

  const [whoIsOpen, setWhoIsOpen] = useState<number>(-1);

  const [subjectData, setSubjectData] = useState<ISubjects[]>();

  const [subjectTable, setSubjectTable] = useState<ITableData[]>([
    {
      title: [],
      content: [],
    },
  ]);

  const theme = useTheme();

  const orderContent = (a: ISubjectContent, b: ISubjectContent) => {
    const order = {
      'Conteúdo da Disciplina': 0,
      'Conteúdo Auxiliar': 1,
      Avaliações: 2,
    };

    return order[a.contentName.trim()] - order[b.contentName.trim()];
  };

  const targetRef = useRef<HTMLDivElement>(null);
  /** Função para rolar para o elemento de destino */
  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  /** UseEfect para Rolar até a disciplina em foco */
  useEffect(() => {
    scrollToTarget();
  }, [target]);

  /** Função responsável de validar se a disciplina tem materiais à ser acessado */
  const handleDisciplineOpen = (
    disciplineId: number,
    content: ISubjectContent[],
    disciplineName: string,
    moduleName: string,
    examInProgress: boolean,
  ) => {
    const newContent = content.sort(orderContent);
    if (newContent.length <= 0) {
      return toastErrorMessage(
        'Nenhum material cadastrado para essa disciplina. Favor entrar em contato com o NAE',
      );
    }
    const courseId = id;

    setIsClassOpen(true);
    setClassInfo({
      id: disciplineId,
      content: newContent.sort(orderContent),
      title: disciplineName,
      subtitle: moduleName,
      courseId: +courseId!,
      examInProgress: examInProgress,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    const courseId = id;

    if (courseId && target !== +courseId) {
      setTarget(+courseId);
    }
    setTarget(+id);

    const load = async () => {
      try {
        const { result, data } =
          await myCoursesServiceNewStudent.getDisciplines(
            courseId ? +courseId : id,
          );
        if (result) {
          setSubjectData(data);
          const dataTable = data.map((item) => {
            return {
              title: [
                {
                  item: (
                    <h6 style={{ fontWeight: 'bold' }}>
                      Disciplinas do {item.moduleName}
                    </h6>
                  ),
                },
                {
                  item: (
                    <S.DivColumn>
                      <h6 style={{ fontWeight: 'bold' }}>Carga Horária</h6>
                    </S.DivColumn>
                  ),
                },
              ],
              content: item.disciplines.map((subject) => ({
                name: {
                  item: (
                    <S.H6Subject
                      onClick={() =>
                        handleDisciplineOpen(
                          subject.disciplineId,
                          subject.content,
                          subject.disciplineName,
                          item.moduleName,
                          subject.examInProgress,
                        )
                      }
                    >
                      {subject.disciplineName}
                    </S.H6Subject>
                  ),
                },
                minimumTime: {
                  item: (
                    <S.DivColumn>
                      <h6>
                        {+subject.workload === 0
                          ? '-'
                          : subject.workload === '-'
                          ? subject.workload
                          : subject.workload + ' horas'}
                      </h6>
                    </S.DivColumn>
                  ),
                  style: { width: '10rem' },
                },
              })),
            };
          });
          setSubjectTable(dataTable);
        }
      } catch (error) {
        throw new Error(error);
      }
      setIsLoading(false);
    };

    load();
  }, []);

  return (
    <S.Container ref={id === target ? targetRef : null}>
      {handleMaxWidth(theme.size?.medium)
        ? subjectData &&
          subjectData.map((item, index) => (
            <S.Content key={index}>
              <S.Title>Disciplinas do {item.moduleName}</S.Title>
              <Divider padding />

              {item.disciplines.map((subject) => (
                <div key={subject.disciplineId}>
                  <S.DivExpand
                    onClick={() => {
                      whoIsOpen === subject.disciplineId
                        ? setWhoIsOpen(-1)
                        : setWhoIsOpen(subject.disciplineId);
                    }}
                  >
                    <S.Subtitle>{subject.disciplineName}</S.Subtitle>
                    {whoIsOpen === subject.disciplineId ? (
                      <ExpandLess fontSize="large" />
                    ) : (
                      <ExpandMore fontSize="large" />
                    )}
                  </S.DivExpand>
                  <Divider padding />

                  {whoIsOpen === subject.disciplineId && (
                    <>
                      <S.Focus>
                        <S.KeyValue>
                          <S.Key>Carga Horária: </S.Key>
                          <S.Value>
                            {subject.workload === '-'
                              ? subject.workload
                              : subject.workload + ' horas'}
                          </S.Value>
                        </S.KeyValue>
                        <ButtonSimple
                          color="primary"
                          heightSize="small"
                          widthSize="large"
                          onClick={() =>
                            handleDisciplineOpen(
                              subject.disciplineId,
                              subject.content,
                              subject.disciplineName,
                              item.moduleName,
                              subject.examInProgress,
                            )
                          }
                        >
                          Acessar conteúdos
                        </ButtonSimple>
                      </S.Focus>
                      <Divider padding />
                    </>
                  )}
                </div>
              ))}
            </S.Content>
          ))
        : subjectTable &&
          subjectTable.map((item, index) => (
            <S.DivTable key={index}>
              <TableBasic data={item} />
            </S.DivTable>
          ))}
      <ClassModal
        isOpen={isClassOpen}
        setIsOpen={setIsClassOpen}
        classInfo={classInfo}
      />
    </S.Container>
  );
}
